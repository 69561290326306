import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextPipe } from './text.pipe';
import { DatePipe } from './date.pipe';
import { DatetimePipe } from './datetime.pipe';
import { TimePipe } from './time.pipe';
import { CellPipe } from './cell.pipe';
import { AmountPipe } from './amount.pipe';
import { NumberPipe } from './number.pipe';
import { UnescapePipe } from './unescape.pipe';
import { RoundingDownPointsPipe } from './rounding-down-points.pipe';

/** @ignore */
const PIPE_LIST = [
  TextPipe,
  DatePipe,
  DatetimePipe,
  TimePipe,
  CellPipe,
  AmountPipe,
  NumberPipe,
  UnescapePipe,
  RoundingDownPointsPipe
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: PIPE_LIST,
  exports: PIPE_LIST
})
export class PipesModule { }
