import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { User } from 'firebase/app';
import { from, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { UserBrandStandCenters } from '@common/interfaces/user-brand-stand-centers.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  setPersistence: Promise<void>;

  constructor(private _fireAuthSrv: AngularFireAuth, private fns: AngularFireFunctions) {
    this._fireAuthSrv.setPersistence('session');
  }

  /**
   * register an user by email-password
   */
  register(email: string, password: string): Observable<any> {
    return from(this._fireAuthSrv.createUserWithEmailAndPassword(email, password));
  }

  /**
   * Sign with email-password
   */
  loginToken(token: string): Observable<any> {
    return from(this._fireAuthSrv.signInWithCustomToken(token));
  }

  login(username: string, password: string): Observable<any> {
    return from(this._fireAuthSrv.signInWithEmailAndPassword(username, password));
  }

  /**
   * sign out the current user
   */
  logout(): Observable<any> {
    return from(this._fireAuthSrv.signOut());
  }

  /**
   * Returns an observable with the current user
   */
  getCurrentUser(): Observable<User> {
    return this._fireAuthSrv.user;
  }

  /**
   * Returns an promise with the current user tokenID
   */
  async getTokenId(): Promise<string> {
    const user: firebase.User = await this._fireAuthSrv.currentUser;
    return user.getIdToken();
  }

  /**
   * Adds an observer for changes to the user's sign-in state.
   */
  onAuthStateChange(): Observable<any> {
    return new Observable((observer) => {
      const onAuthStateChanged = this._fireAuthSrv.onAuthStateChanged(
        (user: firebase.User) => {
          observer.next(user);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  /**
   * Adds an observer for changes to the tokenID state.
   */
  onTokenIdChanged(): Observable<any> {
    return new Observable((observer) => {
      const onIdTokenChanged = this._fireAuthSrv.onIdTokenChanged(
        (user) => {
          observer.next(user);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public cfLoginOld(data) {
    return this.fns.httpsCallable('cf_login')(data);
  }

  public cfLogin(data){
    return from(new Promise(async (resolve, reject) => {
      try {
        const resp = await fetch(environment.middle, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            "endpoint": "cf_login",
            "key": environment.middleKey,
            data
          })
        });

        // En caso de fallo, revisar objeto de respuesta, puede ser json() o text()
        resolve(resp.json());
      }
      catch(err){
        reject(err);
      }
    }));
  }


  public updateDefaultUserStandKey = (userId: string, stand: UserBrandStandCenters) => {
    return from(new Promise(async (resolve, reject) => {
      try {
        const resp = await fetch(environment.middle, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            "endpoint": "cfUpdateDefaultUserStandKey",
            "key": environment.middleKey,
            data: {
              userId,
              standId: stand.ID_STAND?.toString(),
              brandId: stand.ID_MARCA?.toString(),
              centerId: stand.ID_CENTRO?.toString()
            }
          })
        });
        resolve(resp);
      }
      catch (err) {
        reject(err);
      }
    }));
  };
}
