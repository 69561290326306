import { Component, OnInit, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PageEvent } from '@angular/material/paginator';

import { MetaSimpleComponent } from './meta-simple.component';
import { TABLE_SETTINGS } from '@common/const/table.const';
import { TableSettingType, FilterTableType, SortTableType } from '@common/types/table.type';

import _ from 'lodash';

@Component({
  selector: 'app-meta-list',
  template: ''
})
export class MetaListComponent extends MetaSimpleComponent implements OnInit {
  public table: TableSettingType = {
    rows: [],
    data: [],
    columns: [],
    filter: [],
    loader: false,
    clientSide: false,
    paginator: {
      offset: 0,
      limit: TABLE_SETTINGS.standart.limit,
      limitOptions: TABLE_SETTINGS.standart.options,
      total: 0,
      orderBy: [],
      orderBySingleMode: true
    },
    actions: {
      select: false,
      delete: false
    }
  };

  private dataClientBackup: Array<any> = [];
  private _metaTranslateSrv: TranslateService;
  /** constructor */
  constructor(private injectorMeta: Injector) {
    super(injectorMeta);
    this._metaTranslateSrv = injectorMeta.get(TranslateService);
    this.fillEmptyRows();
  }

  ngOnInit() {

  }

  /************************ DOM METHODS **************************/

  public onPage(event: PageEvent | any) {
    this.table.paginator.limit = event.pageSize;

    if (this.table.clientSide) {
      this.setPageClientSide(event.pageIndex);
    }
  }

  /************************ PROTECTED METHODS **************************/

  /**
   * Call method only in constructor because delete data table
   */
  protected setStandartSize() {
    this.table.paginator.limit = TABLE_SETTINGS.standart.limit;
    this.table.paginator.limitOptions = TABLE_SETTINGS.standart.options;
    this.setDataTable([]);
  }

  /**
   * Call method only in constructor because delete data table
   */
  protected setMiniSize() {
    this.table.paginator.limit = TABLE_SETTINGS.mini.limit;
    this.table.paginator.limitOptions = TABLE_SETTINGS.mini.options;
    this.setDataTable([]);
  }

  /**
   * Update table rows
   * @param [total] total: required if clientSide is false
   * @param [keepPage] keepPage: keep actual pagination
   */
  protected setDataTable(data: Array<any>, total?: number, keepPage?: boolean) {
    this.table.data = !_.isNil(data) && _.isArray(data) ? data : [];

    if (this.table.clientSide) {
      this.dataClientBackup = _.cloneDeep(this.table.data);
      this.setPageClientSide(keepPage ? null : 0);

    } else {
      this.table.paginator.total = !_.isNil(total) ? total : 0;
      this.table.rows = this.table.data;
      this.fillEmptyRows();
    }
  }

  /**
   * Add column
   */
  protected addColum(cName: string, position: number = 0) {
    this.table.columns.splice(position, 0, cName);
  }

  /**
   * remove column
   */
  protected removeColum(cName: string) {
    _.pull(this.table.columns, cName);
  }

  /**
   * Add filter condition
   */
  protected addFilter(path: string, value: any, operator?: string) {
    if (!this.table.clientSide) {
      console.error('ERROR: filter only available for clientSide datatable mode');
      return;
    }

    const filterIdx = _.findIndex(this.table.filter, { path });

    if (filterIdx === -1) {
      this.table.filter.push({ path, value, operator } as FilterTableType);
    } else {
      this.table.filter[filterIdx] = { path, value, operator } as FilterTableType;
    }

    this.setPageClientSide(0);
  }

  /**
   * Remove filter condition
   */
  protected removeFilter(path: string) {
    if (!this.table.clientSide) {
      console.error('ERROR: filter only available for clientSide datatable mode');
      return;
    }

    _.pullAt(this.table.filter, _.findIndex(this.table.filter, { path }));
    this.setPageClientSide(0);
  }

  /**
   * Add sort condition (single mode)
   */
  protected addSort(path: string, direction: string = 'asc') {
    if (this.table.paginator.orderBySingleMode) {
      this.table.paginator.orderBy = [{ path, direction } as SortTableType];

    } else {
      const sortIDx = _.findIndex(this.table.paginator.orderBy, { path });

      if (sortIDx === -1) {
        this.table.paginator.orderBy.push({ path, direction } as SortTableType);
      } else {
        this.table.paginator.orderBy[sortIDx] = { path, direction } as SortTableType;
      }
    }

    if (this.table.clientSide) {
      this.setPageClientSide(0);
    }
  }

  /**
   * Remove sort condition (single mode)
   */
  protected removeSort(path: string) {
    if (this.table.paginator.orderBySingleMode) {
      this.table.paginator.orderBy = [];

    } else {
      _.pullAt(this.table.paginator.orderBy, _.findIndex(this.table.paginator.orderBy, { path }));
    }

    if (this.table.clientSide) {
      this.setPageClientSide(0);
    }
  }

  /************************ PRIVATE METHODS **************************/
  /**
   * Filter data table. Only for clientSide mode and after initial setDataTable
   */
  private filterDataTable() {
    if (!this.table.data) {
      return;
    }

    this.table.data = _.filter(this.dataClientBackup, (item) => {
      if (_.isNil(this.table.filter) || _.isEmpty(this.table.filter)) {
        return true;
      }

      const nloop = this.table.filter.length;

      for (let i = 0; i < nloop; i++) {
        if (_.isDate(this.table.filter[i].value)) {
          // @TODO: pendiente de realizar
          continue;
        }

        if (_.isNumber(this.table.filter[i].value)) {
          // @TODO: pendiente de realizar
          continue;
        }

        if (_.isBoolean(this.table.filter[i].value)) {
          if (this.table.filter[i].value !== _.get(item, this.table.filter[i].path)) {
            return false;
          }
          continue;
        }

        const condition = _.isArray(this.table.filter[i].value) ? this.table.filter[i].value : [this.table.filter[i].value];
        const nloop2 = condition.length;

        for (let j = 0; j < nloop2; j++) {
          const regEx = new RegExp(condition[j] || '', 'gi');
          if (regEx.test(_.get(item, this.table.filter[i].path))) {
            return true;
          }
        }
      }

      return false;
    });
  }

  private sortDataTable() {
    this.table.data = _.orderBy(this.table.data, _.map(this.table.paginator.orderBy, 'path'), _.map(this.table.paginator.orderBy, 'direction'));
  }

  /**
   * Pagination method for client side
   * @param offset: actual page to show
   */
  private setPageClientSide(offset?: number) {
    this.filterDataTable();
    this.sortDataTable();

    this.table.paginator.total = this.table.data.length;

    if (!_.isNil(offset)) {
      this.table.paginator.offset = offset;
    }
    const start = this.table.paginator.offset * this.table.paginator.limit;
    const end = Math.min((start + this.table.paginator.limit), this.table.data.length);

    this.table.rows = this.table.data.slice(start, end);
    this.fillEmptyRows();
  }

  /**
   * fill the rows with empty data if it is not a multiple of the limit
   */
  private fillEmptyRows() {
    if (this.table.rows.length < this.table.paginator.limit) {
      const nloop = this.table.paginator.limit;
      for (let i = this.table.rows.length; i < nloop; i++) {
        this.table.rows.push({});
      }
    }
  }
}
