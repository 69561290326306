import { ConstructorModel } from '../../core/models/constructor.model';
import { UserModel } from '../user/user.model';
import { RoleModel } from '../user/role.model';

import _ from 'lodash';

export class SessionModel extends ConstructorModel {
  uid: string;
  username: string;
  token: string;
  photoURL: string;
  refreshToken: string;
  user: UserModel;

    /** model constructor */
  constructor(data?: object) {
    super();

    this.uid = this.setString('uid', data);
    this.username = this.setString('username', data);
    this.token = this.setString('token', data);
    this.photoURL = this.setString('photoURL', data);
    this.refreshToken = this.setString('refreshToken', data);
    this.user = this.setEntity('user', UserModel, data);
  }
}
