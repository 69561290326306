import { Injectable } from '@angular/core';
import { PermAction } from '@common/enums/permission.enum';
import { PermissionBack } from '@common/models/permission-back.model';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { map } from 'rxjs/operators';
import { UserProviderService } from '../providers/user-provider.service';
import { RolesService } from '../role/roles.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  public readonly collectionRoles = 'ROLES';
  private permissions: PermissionBack[];

  constructor(
    private rolesService: NgxRolesService,
    private permissionsService: NgxPermissionsService,
    private userService: UserProviderService,
    private roleService: RolesService
  ) {
  }

  public loadPermissions() {
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    if (!this.permissions) {
      this.removePermissions();
      this.roleService.getRoleById(this.userService.user.role.id).pipe(
        map(roles => {
          this.rolesService.addRoleWithPermissions(roles.ROL_Descripcion, [PermAction.Insertar, PermAction.Modificar]);
          const rolesMap = Object.keys(roles.Opciones);
          Object.values(roles.Opciones).forEach((role, i) => {
            const permissionMap = [];
            const roleValue = Object.values(role);
            Object.keys(role).forEach((perm, index) => {
              perm === PermAction.Datos || perm === PermAction.Tope
                ? permissionMap.push(rolesMap[i] + perm + roleValue[index])
                : permissionMap.push(rolesMap[i] + perm);
            });
            this.rolesService.addRoleWithPermissions(rolesMap[i], permissionMap);
          });
          localStorage.setItem('permissions', JSON.stringify(this.getPermissionRoles()));
        }),
      ).subscribe();
    } else {
      const val = Object.values(this.permissions);
      Object.values(val).forEach(perm => {
        this.rolesService.addRoleWithPermissions(perm.name, perm.validationFunction);
      });
    }
  }

  public getPermissionRoles() {
    return this.rolesService.getRoles();
  }

  public removePermissions() {
    this.rolesService.flushRolesAndPermissions();
    if (this.permissions) {
      const val = Object.values(this.permissions);
      Object.values(val).forEach(perm => {
        this.rolesService.removeRole(perm.name);
        this.permissionsService.removePermission((perm.name));
      });
    }
  }

}
