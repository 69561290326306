import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowCellDirective } from './show-cell.directive';

const DIRECTIVE_LIST = [
  ShowCellDirective
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: DIRECTIVE_LIST,
  exports: DIRECTIVE_LIST
})
export class ShowCellDirectiveModule { }
