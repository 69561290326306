import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import _ from 'lodash';

const ENVIRONMENT = environment;

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() { }

  /**
   * Print a console.log
   */
  printl(element: any, message: any, method?: string, extra?: any) {
    if (ENVIRONMENT.logs) {
      _.isNil(extra)
        ? console.log(`${_.get(element, 'constructor.name')}${method ? '::' + method : ''}`, message)
        : console.log(`${_.get(element, 'constructor.name')}${method ? '::' + method : ''}`, message, extra);
    }
  }

  /**
   * Print a console.warn
   */
  printW(element: any, message: any, method?: string, extra?: any) {
    if (ENVIRONMENT.logs) {
      _.isNil(extra)
        ? console.warn(`${_.get(element, 'constructor.name')}${method ? '::' + method : ''}`, message)
        : console.warn(`${_.get(element, 'constructor.name')}${method ? '::' + method : ''}`, message, extra);
    }
  }
}
