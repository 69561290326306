import { ConstructorModel } from '@common/core/models/constructor.model';
import { FirestoreType, ModelAdapter } from '@common/interfaces/firestore/firestore.interface';
import { PisoRoleMenuOptionModel } from './piso-role-menu-option.model';

export class PisoRoleModel extends ConstructorModel {
  id: string;
  path: string;
  nidActive: number;
  nidAtalaya: number;
  nidRole: number;
  pisoRoleMenuOptions: Array<PisoRoleMenuOptionModel>;

  static adapter(): ModelAdapter {
    return {
        nidActive: { name: 'NID_ACTIVO', type: FirestoreType.string },
        nidAtalaya: { name: 'NID_ATALAYA', type: FirestoreType.string },
        nidRole: { name: 'NID_ROL', type: FirestoreType.string },
        pisoRoleMenuOptions: { name: 'PISO_ROLES_OPCIONES_MENU', type: FirestoreType.collection, modelClass: PisoRoleMenuOptionModel }
      };
  }

    /** model constructor */
  constructor(data?: object) {
    super();

    this.id = this.setId('id', data);
    this.path = this.setString('path', data);
    this.nidActive = this.setNumber('nidActive', data);
    this.nidAtalaya = this.setNumber('nidAtalaya', data);
    this.pisoRoleMenuOptions = this.setEntityList('pisoRoleMenuOptions', PisoRoleMenuOptionModel, data);
  }
}
