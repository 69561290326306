import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoaderService } from '@common/services/shared/loader.service';
import { LoaderItem } from '@common/interfaces/loader-item.interface';

import _ from 'lodash';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  public loaderPooling = [];
  public message: string;
  private _subscriber: Subscription;
  /** constructor */
  constructor(private _loaderSrv: LoaderService) { }

  ngOnInit() {
    this._subscriber = this._loaderSrv.getLoader().subscribe(
      (loader: LoaderItem) => {
        setTimeout(() => {
          loader.status
                  ? this.loaderPooling.push({ msg: _.get(loader, 'msg') })
                  : this.loaderPooling.splice(0, 1);

          this.message = _.get(_.last(this.loaderPooling), 'msg');
        });
      },
      () => {
        this.clearPooling();
      }
  );
  }

  ngOnDestroy() {
    this.clearPooling();
    if (this._subscriber && !this._subscriber.closed) {
      this._subscriber.unsubscribe();
    }
  }

  private clearPooling() {
    setTimeout(() => {this.loaderPooling = []; });
  }
}
