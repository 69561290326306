import { ConstructorModel } from '@common/core/models/constructor.model';
import { FirestoreType, ModelAdapter } from '@common/interfaces/firestore/firestore.interface';
import { StandBrandModel } from './stand-brand.model';

export class StandModel extends ConstructorModel {
  id: string;
  path: string;
  vipQuantity: number;
  description: string;
  dto: number;
  phone: string;
  email: string;
  oldEmail: string;
  fax: string;
  PermiteArticulosSinPuntos: boolean;
  registrationDate: string;
  unregistrationDate: string;
  calendarId: number;
    // center: CenterModel;
  brands: Array<StandBrandModel>;

  static adapter(): ModelAdapter {
    return {
        vipQuantity: { name: 'CANTIDAD_VIP', type: FirestoreType.number },
        description: { name: 'DESCRIPCION_STAND', type: FirestoreType.string },
        dto: { name: 'DTO', type: FirestoreType.number },
        phone: { name: 'TELEFONO_STAND', type: FirestoreType.string },
        email: { name: 'EMAIL_STAND', type: FirestoreType.string },
        oldEmail: { name: 'EMAIL_ANTIGUO', type: FirestoreType.string },
        fax: { name: 'FAX_STAND', type: FirestoreType.string },
        PermiteArticulosSinPuntos: { name: 'PermiteArticulosSinPuntos', type: FirestoreType.boolean },
        registrationDate: { name: 'FECHA_ALTA_STAND', type: FirestoreType.datetime },
        unregistrationDate: { name: 'FECHA_BAJA_STAND', type: FirestoreType.datetime },
        calendarId: { name: 'ID_AGENDA', type: FirestoreType.number },
            // center: { name: 'Ref_ID_CENTRO', type: FirestoreType.reference, modelClass: CenterModel},
        brands: { name: 'STAND_MARCAS', type: FirestoreType.collection, modelClass: StandBrandModel }
      };
  }

    /** model constructor */
  constructor(data?: object) {
    super();

    this.id = this.setId('id', data);
    this.path = this.setString('path', data);
    this.vipQuantity = this.setNumber('vipQuantity', data);
    this.description = this.setString('description', data);
    this.dto = this.setNumber('dto', data);
    this.phone = this.setString('phone', data);
    this.email = this.setString('email', data);
    this.oldEmail = this.setString('oldEmail', data);
    this.fax = this.setString('fax', data);
    this.PermiteArticulosSinPuntos = this.setBoolean('PermiteArticulosSinPuntos', data);
    this.registrationDate = this.setDate('registrationDate', data);
    this.unregistrationDate = this.setDate('unregistrationDate', data);
    this.calendarId = this.setNumber('calendarId', data);
        // this.center = this.setEntity('center', CenterModel, data);
    this.brands = this.setEntityList('brands', StandBrandModel, data);
  }
}
