import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Permission } from '@common/enums/permission.enum';
import { AuthGuard } from '@common/guards/auth.guard';
import { AuthGuardPerf } from '@common/guards/authperf.guard';
import { SessionGuard } from '@common/guards/session.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AppRoutes } from './app-routes.enum';

const routes: Routes = [
  {
    path: AppRoutes.LOGIN,
    pathMatch: 'full',
    canActivate: [SessionGuard],
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule)
  },
  {
    path: AppRoutes.RESET,
    loadChildren: () => import('./features/reset/reset.module').then(m => m.ResetModule)
  },
  {
    path: AppRoutes.PANEL,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./features/panel/panel.module').then(m => m.PanelModule)
  },
  {
    path: AppRoutes.PANELPERFUMERIAS,
    canActivate: [AuthGuardPerf],
    canActivateChild: [AuthGuardPerf],
    loadChildren: () => import('./features/panelPerfumerias/panel.module').then(m => m.PanelModule),
  },
  { path: '**', redirectTo: AppRoutes.PANEL }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    scrollOffset: [0, 100],
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
