import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppRoutes } from '@app/app-routes.enum';
import { AuthenticationService } from '@common/services/providers/authentication.service';
@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) { }

  canActivate(): Observable<boolean> {
    return this.authService.getCurrentUser().pipe(
      map(user => {
        if (user) {
          this.router.navigate(['/', AppRoutes.PANEL]);
          return false;
        }

        return true;
      })
    );
  }
}
