import { ConstructorModel } from '@common/core/models/constructor.model';
import { FirestoreType, ModelAdapter } from '@common/interfaces/firestore/firestore.interface';
import { PisoMenuOptionModel } from './piso-menu-option.model';
import { PisoRoleModel } from './piso-role.model';

export class PisoRoleMenuOptionModel extends ConstructorModel {
  id: string;
  path: string;
    // pisoRole: PisoRoleModel;
  permissionTxt: string;
  allowAdd: boolean;
  allowEdit: boolean;
  allowRead: boolean;
  allowRemove: boolean;
  pisoMenuOptions: Array<PisoMenuOptionModel>;

  static adapter(): ModelAdapter {
    return {
            // pisoRole: {name: 'Ref_NID_ROL', type: FirestoreType.reference, modelClass: PisoRoleModel},
        permissionTxt: { name: 'TXT_PERMISO', type: FirestoreType.string },
        allowAdd: { name: 'permidoInsertar', type: FirestoreType.boolean },
        allowEdit: { name: 'permisoEditar', type: FirestoreType.boolean },
        allowRead: { name: 'permisoVer', type: FirestoreType.boolean },
        allowRemove: { name: 'permisoBorrar', type: FirestoreType.boolean },
        pisoMenuOptions: { name: 'Ref_NID_MENU', type: FirestoreType.reference, modelClass: PisoMenuOptionModel }
      };
  }

    /** model constructor */
  constructor(data?: object) {
    super();

    this.id = this.setId('id', data);
    this.path = this.setString('path', data);
        // this.pisoRole = this.setEntity('pisoRole', PisoRoleModel, data);
    this.permissionTxt = this.setString('permissionTxt', data);
    this.allowAdd = this.setBoolean('allowAdd', data);
    this.allowEdit = this.setBoolean('allowEdit', data);
    this.allowRead = this.setBoolean('allowRead', data);
    this.allowRemove = this.setBoolean('allowRemove', data);
    this.pisoMenuOptions = this.setEntityList('pisoMenuOptions', PisoMenuOptionModel, data);
  }
}
