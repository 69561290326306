import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { firestore } from 'firebase/app';
import { Zone } from '@common/interfaces/zone.interface';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ZoneService {
  public static readonly collectionName = 'TIPO_ZONAS';

  constructor(private af: AngularFirestore) { }

  public getAllZones(): Observable<Zone[]> {
    let datoUsuarioDiv  = JSON.parse(localStorage.getItem('userDiv'));
    let idDiv = datoUsuarioDiv.roleData.ID_DIVISION;
    // let idDiv = '01';
    if (idDiv === '01') {
      return this.af.collection<Zone>(ZoneService.collectionName).valueChanges()
        .pipe(
          tap(zones => this.ordenarZonas(zones)),
          map(zones => zones.filter(zone => zone.ID_USUARIO && zone.GC === true))
        );
    }else{
      return this.af.collection<Zone>(ZoneService.collectionName).valueChanges()
        .pipe(
          tap(zones =>  this.ordenarZonas(zones)),
          map(zones => zones.filter(zone => zone.ID_USUARIO && zone.GC !== true))
        );
    }
  }

  public getZoneById(id: number): Observable<Zone> {
    return this.af.doc<Zone>(`${ZoneService.collectionName}/${id}`).valueChanges();
  }

  public getZoneByRef(ref: firestore.DocumentReference): Observable<Zone> {
    return this.af.doc<Zone>(ref).valueChanges();
  }

  public getZoneRefById(id: number): DocumentReference<Zone> {
    return this.af.doc<Zone>(`${ZoneService.collectionName}/${id}`).ref;
  }

  public ordenarZonas(items:any[]) {
    return items.sort(function(a,b){
        if (a.DESCRIPCION > b.DESCRIPCION ) {
          return 1;
        }
        if (a.DESCRIPCION  < b.DESCRIPCION ) {
          return -1;
        }
        return 0;
      });
    }
}
