export const TABLE_SETTINGS = {
  standart: {
      limit: 10,
      options: [10, 15 , 20]

    },
  mini: {
      limit: 5,
      options: null

    }
};
