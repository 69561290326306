import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Observable, zip } from 'rxjs';
import { switchMap, map, defaultIfEmpty, tap } from 'rxjs/operators';
import { firestore } from 'firebase/app';
import { Brand } from '@common/interfaces/brand.interface';
import { Zone } from '@common/interfaces/zone.interface';
import { Center } from '@common/interfaces/center.interface';
import { Stand } from '@common/interfaces/stand.interface';
import { StandService } from '../stand/stand.service';
import { CenterService } from '../center/center.service';

@Injectable({
  providedIn: 'root'
})
export class BrandService {
  public static readonly collectionName = 'MARCAS';
  public static readonly subcollectionStands = 'STAND_MARCAS';

  public datoUsuarioDiv : any;

  constructor(
    private af: AngularFirestore,
    private centerService: CenterService,
    private standService: StandService
  ) { }

  public getAllBrands(): Observable<Brand[]> {
    this.datoUsuarioDiv  = JSON.parse(localStorage.getItem('userDiv'));
    let idDiv = this.datoUsuarioDiv.roleData.ID_DIVISION;
    return this.af.collection<Brand>(BrandService.collectionName)
      .valueChanges()
      .pipe(
        tap(brands => this.ordenarMarcas(brands)),
        map(brands => brands.filter(brand =>
          brand.FECHA_ALTA_MARCA > brand.FECHA_BAJA_MARCA && brand.ID_DIVISION === idDiv
        ))
      );
  }
  
  public getAllBrandsWithCustomDivision(idDivision): Observable<Brand[]> {
    this.datoUsuarioDiv  = JSON.parse(localStorage.getItem('userDiv'));
    return this.af.collection<Brand>(BrandService.collectionName)
      .valueChanges()
      .pipe(
        tap(brands => this.ordenarMarcas(brands)),
        map(brands => brands.filter(brand =>
          brand.FECHA_ALTA_MARCA > brand.FECHA_BAJA_MARCA && brand.ID_DIVISION === idDivision
        ))
      );
  }

  public getAllActiveBrands(): Observable<Brand[]> {
    return this.af.collection<Brand>(
      BrandService.collectionName,
      ref => ref.where('FECHA_BAJA_MARCA', '==', null)
    ).valueChanges();
  }

  public getBrandsByZone(zone: Zone, zztt?: boolean): Observable<Brand[]> {
    this.datoUsuarioDiv  = JSON.parse(localStorage.getItem('userDiv'));
    let idDiv = this.datoUsuarioDiv.roleData.ID_DIVISION;
    return this.centerService.getCentersByZone(zone, zztt).pipe(
      switchMap(centers => zip(...centers.map(center => this.getBrandsByCenter(center)))),
      map(centers => centers.flat()),
      map(brands => this.getUniqueBrands(brands)),
      map(brands => brands.filter(brand =>
        brand.FECHA_ALTA_MARCA > brand.FECHA_BAJA_MARCA && brand.ID_DIVISION === idDiv
      )),
      tap(brands => this.ordenarMarcas(brands))
    );
  }
  public getBrandsByCenter(center: Center): Observable<Brand[]> {
    this.datoUsuarioDiv  = JSON.parse(localStorage.getItem('userDiv'));
    let idDiv = this.datoUsuarioDiv.roleData.ID_DIVISION;
    return this.standService.getStandsByCenter(center).pipe(
      switchMap(stands => zip(...stands.map(stand => this.getBrandsByStand(stand)))),
      map(brands => brands.flat()),
      map(brands => this.getUniqueBrands(brands)),
      map(brands => brands.filter(brand =>
        brand.FECHA_ALTA_MARCA > brand.FECHA_BAJA_MARCA  && brand.ID_DIVISION === idDiv
      )),
      tap(brands => this.ordenarMarcas(brands))
    );
  }

  public getBrandsByStand(stand: Stand): Observable<Brand[]> {
    this.datoUsuarioDiv = JSON.parse(localStorage.getItem('userDiv'));
    let idDiv = this.datoUsuarioDiv.roleData.ID_DIVISION;
    const Ref_STAND_MARCAS =
      Array.isArray(stand.Ref_STAND_MARCAS)
        ? stand.Ref_STAND_MARCAS
        : Object.keys(stand.Ref_STAND_MARCAS).map(key => stand.Ref_STAND_MARCAS[key])

    return zip(
      ...Ref_STAND_MARCAS.map(brandRef => this.getBrandByRef(brandRef))
    ).pipe(
      map(brands => brands.filter(brand =>
        brand.FECHA_ALTA_MARCA > brand.FECHA_BAJA_MARCA && brand.ID_DIVISION === idDiv
      )),
      tap(brands => this.ordenarMarcas(brands)),
      defaultIfEmpty([] as Brand[])
    );
  }

  public getBrandById(id: number): Observable<Brand> {
    return this.af.doc<Brand>(`${BrandService.collectionName}/${id}`).valueChanges();
  }

  public getBrandByRef(ref: firestore.DocumentReference): Observable<Brand> {
    return this.af.doc<Brand>(ref).valueChanges();
  }

  public getUniqueBrands(brands: Brand[]): Brand[] {
    const hash: { [key: number]: boolean } = { };
    return brands.filter(brand => hash[brand.ID_MARCA] ? false : hash[brand.ID_MARCA] = true);
  }

  public getBrandRefById(id: number): DocumentReference<Brand> {
    return this.af.doc<Brand>(`${BrandService.collectionName}/${id}`).ref;
  }

  public ordenarMarcas(items:any[]) {
    return items.sort(function(a,b){
      if (a.NOMBRE_MARCA > b.NOMBRE_MARCA ) {
        return 1;
      }
      if (a.NOMBRE_MARCA  < b.NOMBRE_MARCA ) {
        return -1;
      }
      return 0;
    });
  }
}
