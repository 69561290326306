import { ConstructorModel } from '@common/core/models/constructor.model';
import { FirestoreType, ModelAdapter } from '@common/interfaces/firestore/firestore.interface';

export class ZoneTypeModel extends ConstructorModel {
  id: string;
  path: string;
  description: string;
  aq: number;

  static adapter(): ModelAdapter {
    return {
        description: { name: 'DESCRIPCION', type: FirestoreType.string },
        aq: { name: 'ZONA_AQ', type: FirestoreType.number }
      };
  }

    /** model constructor */
  constructor(data?: object) {
    super();

    this.id = this.setId('id', data);
    this.path = this.setString('path', data);
    this.description = this.setString('description', data);
    this.aq = this.setNumber('aq', data);
  }
}
