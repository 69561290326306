import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@environments/environment';

import _ from 'lodash';
/** @ignore */
const ENVIRONMENT = environment;

/** Pipe for imports */
@Pipe({
  name: 'amount'
})
export class AmountPipe implements PipeTransform {

  /** class constructor */  /** constructor */
  constructor(private _translateSrv: TranslateService) { }

  /**
   * returns amount formatted
   * @example {{text | amount:2.2}}
   */
  transform(
    value: number | string,
    digitsInfo: string = '1.2-2',
    keyCurrency: string = 'Metric.Currency',
    defaultValue: string = '-'
  ): string {
    const locale = ENVIRONMENT.language.default;

    return !_.isNil(value) && (_.isNumber(value) || (_.isString(value) && value !== ''))
      ? formatNumber(Number(value),  locale, digitsInfo) + this._translateSrv.instant(keyCurrency)
      : defaultValue;
  }

}
