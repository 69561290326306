import { Pipe, PipeTransform } from '@angular/core';

import _ from 'lodash';

/** Pipe for datatable cells */
@Pipe({
  name: 'cell'
})
export class CellPipe implements PipeTransform {

  transform(value: any, cell: any): any {
    return !_.isNil(cell) && !_.isEmpty(cell) ? value : '';
  }
}
