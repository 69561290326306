import { environment } from '@environments/environment';
import { firestore } from 'firebase/app';

import _ from 'lodash';
import moment from 'moment';

export function date4Api(date: any) {
  if (_.get(environment, 'addTimeZoneOffset')) {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
  }

  return moment(date).toISOString();
}

export function fireTimestampToDate(data: firestore.Timestamp): Date {
  if (_.isNil(data) || _.isEmpty(data) || !(data instanceof firestore.Timestamp)) {
    return null;
  }

  return data.toDate();
}

export function dateToFireTimestamp(date: any): firestore.Timestamp {
  if (_.isNil(date) || _.isEmpty(date)) {
    return date;
  }

  return date instanceof firestore.Timestamp
        ? date
        : firestore.Timestamp.fromDate(date);
}

/**
 * Cast firestore Blob to base64
 */
export function fireBlobToBase64(data: firestore.Blob): string {
  if (_.isNil(data) || _.isEmpty(data) || !(data instanceof firestore.Blob)) {
    return null;
  }

  return data.toBase64();
}

export function base64ToFireBlob(data: string | firestore.Blob): firestore.Blob {
  if (_.isNil(data) || _.isEmpty(data)) {
    return null;
  }

  return data instanceof firestore.Blob
        ? data
        : firestore.Blob.fromBase64String(data);
}

export function chunksBuilder(array, chunkSize) {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }
  return chunks;
}

export function filterItemsByUnfulfilledCancellationDate<T>(data: T[], key: string): T[] {
  return data.filter(ele => {
    return ele && (ele[key] === null ||
      moment(new Date())?.isBefore(moment(new Date(ele[key]?.toDate()))))
  }
  );
}