// tslint:disable:directive-selector
import { Directive, Input, ElementRef, OnInit } from '@angular/core';

import _ from 'lodash';

@Directive({
  selector: '[showCell]'
})
export class ShowCellDirective implements OnInit  {
  @Input('showCell') cell: any;
  /** constructor */
  constructor(private _elementRerf: ElementRef) {}

  ngOnInit() {
    if (_.isNil(this.cell) || _.isEmpty(this.cell)) {
      this._elementRerf.nativeElement.innerHTML = '';
    }
  }
}
