import { ConstructorModel } from '@common/core/models/constructor.model';
import { FirestoreType, ModelAdapter } from '@common/interfaces/firestore/firestore.interface';

export class RoleModel extends ConstructorModel {
  id: string;
  path: string;
  description: string;
  options: any;

  static adapter(): ModelAdapter {
    return {
        description: { name: 'ROL_Descripcion', type: FirestoreType.string },
        options: { name: 'Opciones', type: FirestoreType.map }
      };
  }

    /** model constructor */
  constructor(data?: object) {
    super();

    this.id = this.setId('id', data);
    this.path = this.setString('path', data);
    this.description = this.setString('description', data);
    this.options = this.setString('options', data);
  }
}
