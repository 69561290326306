import { AfterContentChecked, Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '.form-group'
})
export class FormGroupStyleDirective implements AfterContentChecked {
  @HostBinding('class.disabled') disabled: boolean;

  constructor(private elRef: ElementRef) { }

  public ngAfterContentChecked(): void {
    const parentEl: Element = this.elRef.nativeElement;

    const childrenDisabled = (element: Node) => {
      if ((element as Element)?.classList?.contains('mat-select')) {
        this.disabled = (element as Element)?.classList?.contains('mat-select-disabled');
        return;
      }

      if (element.childNodes.length) {
        return element.childNodes.forEach(child => childrenDisabled(child));
      }

      if ((element as HTMLInputElement).disabled !== undefined) {
        this.disabled = (element as HTMLInputElement).disabled;
        return;
      }

      return;
    };

    childrenDisabled(parentEl);
  }
}
