export interface FirestorePagination {
  firstDoc: any;
  lastDoc: any;
  availableNext: boolean;
  availablePrev: boolean;
  offset: number;
}

export enum FirestoreType {
    string,
    boolean,
    number,
    datetime,
    byte,
    object,
    array,
    collection,
    reference,
    map
}

export interface FirestoreModelAdapter {
  name: string; // firestore field name
  type: FirestoreType;
  modelClass?: any; // usar para tipo reference
}

export interface ModelAdapter {
  [propName: string]: FirestoreModelAdapter; // forntEnd field name
}
