import { Pipe, PipeTransform } from '@angular/core';

import _ from 'lodash';

/** Pipe for texts */
@Pipe({
  name: 'text'
})
export class TextPipe implements PipeTransform {

  /**
   * Returns text string formatted
   * @example {{text | text:''}}
   */
  transform(value: string, defaultValue: string = '-'): string {
    return !_.isNil(value) && value !== '' ? value : defaultValue;
  }

}
