import { BrowserModule } from '@angular/platform-browser';
import { DEFAULT_CURRENCY_CODE, Injectable, LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// CUSTOM COMPONENTS
import { MetaSimpleComponent } from '@common/core/components/meta-simple.component';
import { MetaFormComponent } from '@common/core/components/meta-form.component';
import { MetaListComponent } from '@common/core/components/meta-list.component';

// THIRD PARTY MODULES
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/performance';
import { firestore } from 'firebase/app';
import { CalendarCommonModule, CalendarDateFormatter, CalendarWeekModule, DateAdapter, DateFormatterParams } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatNativeDateModule } from '@angular/material/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { routerKey } from './shared/store';

// CUSTOM MODULES
import { AppRoutingModule } from './app-routing.module';
import { LoaderNavigationModule } from '@common/components/loader-navigation/loader-navigation.module';
import { LoaderModule } from '@common/components/loader/loader.module';
import { PipesModule } from '@common/pipes/pipes.module';
import { CommonDirectivesModule } from '@common/directives/common-directives.module';
import { ShowCellDirectiveModule } from '@common/directives/table/show-cell-directives.module';
import { StyleDirectivesModule } from '@common/directives/style/style-directives.module';
import { AlertModule } from './common/components/alert/alert.module';

// LOCALE REGISTRATION
import localeES from '@angular/common/locales/es';
import { formatDate, registerLocaleData } from '@angular/common';
import { environment } from '@environments/environment';

// CUSTOM SERVICES
import { LoaderService } from '@common/services/shared/loader.service';
import { AlertService } from '@common/services/shared/alert.service';
import { SessionService } from '@common/services/shared/session.service';
import { StyleService } from '@common/services/shared/style.service';
import { MaterialModule } from './shared/material/material.module';
import {NgxPermissionsModule} from 'ngx-permissions';

/** @ignore */
const ENVIRONMENT = environment;
/** @ignore */
registerLocaleData(localeES);

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'HH:mm', locale);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    MetaSimpleComponent,
    MetaFormComponent,
    MetaListComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    MatNativeDateModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    }),
    TranslateModule.forRoot({
      defaultLanguage: ENVIRONMENT.language.default,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    CalendarCommonModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    CalendarWeekModule,
    AppRoutingModule,
    LoaderNavigationModule,
    LoaderModule,
    PipesModule,
    CommonDirectivesModule,
    ShowCellDirectiveModule,
    StyleDirectivesModule,
    AngularFireModule.initializeApp(ENVIRONMENT.firebase),
    AngularFirestoreModule.enablePersistence(), // { synchronizeTabs: true }
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFirePerformanceModule,
    StoreModule.forRoot({ [routerKey]: routerReducer }, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictActionWithinNgZone: false,
        strictActionTypeUniqueness: false
      }
    }),
    EffectsModule.forRoot(),
    StoreRouterConnectingModule.forRoot(),
    AlertModule,
    NgxPermissionsModule.forRoot()
  ],
  providers: [
    LoaderService,
    AlertService,
    SessionService,
    StyleService,
    PerformanceMonitoringService,
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    { provide: SETTINGS, useValue: { cacheSizeBytes: firestore.CACHE_SIZE_UNLIMITED } },
    { provide: REGION, useValue: 'europe-west1' },
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
