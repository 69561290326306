import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference, QueryFn } from '@angular/fire/firestore';
import { Observable, of, zip } from 'rxjs';
import { defaultIfEmpty, map, mergeMap, tap } from 'rxjs/operators';
import { firestore } from 'firebase/app';
import { Center } from '@common/interfaces/center.interface';
import { Stand } from '@common/interfaces/stand.interface';
import { CenterService } from '../center/center.service';
import { StandBrand } from '@common/interfaces/stand-brand.interface';
import { SaleItem } from '@common/interfaces/sale-items.interface';
import { ta } from 'date-fns/locale';

@Injectable({
  providedIn: 'root'
})
export class StandService {
  public static readonly collectionName = 'CENTRO_STANDS';
  public static readonly standBrandCollectionName = 'STAND_MARCAS';

  constructor(private af: AngularFirestore) { }

  public getStandsByCenter(center: Center): Observable<Stand[]> {
    const Ref_CENTRO_STANDS =
      Array.isArray(center.Ref_CENTRO_STANDS)
        ? center.Ref_CENTRO_STANDS
        : Object.keys(center.Ref_CENTRO_STANDS).map(key => center.Ref_CENTRO_STANDS[key])

    return zip(
      ...Ref_CENTRO_STANDS.map(standRef => this.getStandByRef(standRef))
    ).pipe(
        map(stands =>
        stands.filter(
          stand => stand && stand.FECHA_ALTA_STAND > stand.FECHA_BAJA_STAND
        )
      ),
      defaultIfEmpty([] as Stand[])
    );
  }

  public getStandById(idCenter: number, id: number): Observable<Stand> {
    return this.af.doc<Stand>(this.getStandPath(idCenter, id)).valueChanges();
  }

  public getStandByRef(ref: firestore.DocumentReference): Observable<Stand> {
    return this.af.doc<Stand>(ref).valueChanges();
  }

  public getStandByOrderIdSale(idOrdenVenta: number): Observable<Stand> {
    const queryFnWithOutBrand: QueryFn = (ref) => ref
      .where('ID_ORDEN_VENTA', '==', idOrdenVenta)

    return this.af.collection<SaleItem>(
      `VENTAS`,
      queryFnWithOutBrand
    ).valueChanges().pipe(
      map(sales => Array.isArray(sales) ? sales[0] : null),
      mergeMap(sale => sale.Ref_ID_STAND ? this.getStandByRef(sale.Ref_ID_STAND) : of(null as Stand))
    );
  }

  public getStandByCenterAndBrand(idCenter: number, idBrand: number): Observable<Stand> {
    return this.af.collection<Stand>(
      `${CenterService.collectionName}/${idCenter}/${StandService.collectionName}`,
      ref => ref
      .where(`STAND_MARCAS.${idBrand}`, '>=', '')
    ).valueChanges().pipe(
      map(stands =>
        stands.filter(
          stand => stand.FECHA_ALTA_STAND > stand.FECHA_BAJA_STAND
        )
      ),
      map(({ 0: stand }) => stand)
    );
  }

  public getUniqueStands(stands: (Stand | { ID_STAND: number })[]): (Stand | { ID_STAND: number })[] {
    const hash: { [key: number]: boolean } = { };
    return stands.filter(stand => hash[stand.ID_STAND] ? false : hash[stand.ID_STAND] = true);
  }

  public getStandRefById(idCenter: number, id: number): DocumentReference<Stand> {
    return this.af.doc<Stand>(this.getStandPath(idCenter, id)).ref;
  }

  public getStandBrand(idCenter: number, idStand: number, idBrand: number): Observable<StandBrand> {
    return this.af
      .doc<StandBrand>(`${this.getStandPath(idCenter, idStand)}/${StandService.standBrandCollectionName}/${idStand}-${idBrand}`)
      .valueChanges();
  }

  /** Updates stands in cache. */
  public updateCache(): Observable<void> {
    return this.af
      .collectionGroup<Stand>(StandService.collectionName)
      .get()
      .pipe(map(() => undefined));
  }

  private getStandPath(idCenter: Center['ID_CENTRO'], id: Stand['ID_STAND']): string {
    return `${CenterService.collectionName}/${idCenter}/${StandService.collectionName}/${id}`;
  }
}
