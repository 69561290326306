import { ConstructorModel } from '@common/core/models/constructor.model';

import _ from 'lodash';
import {
  FirestoreType,
  ModelAdapter
} from '@common/interfaces/firestore/firestore.interface';

export class UserSigningModel extends ConstructorModel {
  entry: boolean;
  dateHour: string;
  idStand: string;
  idUser: string;
  refIdStand: string;
  refIdUser: string;
  lastSigning: string;

  /** model constructor */
  constructor(data?: object) {
    super();

    this.entry = this.setBoolean('entry', data);
    this.dateHour = this.setString('dateHour', data);
    this.idStand = this.setId('idStand', data);
    this.idUser = this.setId('idUser', data);
    this.refIdStand = this.setEntity('refIdStand', data);
    this.refIdUser = this.setEntity('refIdUser', data);
    this.lastSigning = this.setString('lastSigning', data);
  }

  static adapter(): ModelAdapter {
    return {
      entry: { name: 'ES_ENTRADA', type: FirestoreType.boolean },
      dateHour: { name: 'FECHA_HORA', type: FirestoreType.datetime },
      idStand: { name: 'ID_STAND', type: FirestoreType.number },
      idUser: { name: 'ID_USUARIO', type: FirestoreType.number },
      refIdStand: { name: 'Ref_ID_STAND', type: FirestoreType.reference },
      refIdUser: { name: 'Ref_ID_USUARIO', type: FirestoreType.reference },
      lastSigning: { name: 'ULTIMO_TIPO_FICHAJE', type: FirestoreType.number }
    };
  }
}
