import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import _ from 'lodash';
import moment from 'moment';

/** Pipe for dates */
@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {
  /** constructor */
  constructor(private _translateSrv: TranslateService) { }

  /**
   * Returns date string formatted
   * @example {{myDate | date}}
   */
  transform(value:	string | number | Date): string {
    const format = this._translateSrv.instant('DateFormat.Moment.Date');
    const empty = this._translateSrv.instant('DateFormat.Empty.Date');

    return !_.isNil(value) ? moment(value).format(format) : empty;
  }

}
