import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Icons } from './common/const/icons.const';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { SwUpdate } from '@angular/service-worker';
import { interval, SubscriptionLike } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

/** @ignore */
const ENVIRONMENT = environment;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'atalaya-pwa';

  subscriptions$: SubscriptionLike[] = [];
  
  /** constructor */
  constructor(
    private _translateSrv: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private matPaginatorIntl: MatPaginatorIntl,
    private domSanitizer: DomSanitizer,
    private titleService: Title,
    private swUpdateService: SwUpdate,
    private appRef: ApplicationRef
  ) {
    this._translateSrv.setDefaultLang(ENVIRONMENT.language.default);
    this._translateSrv.use(this._translateSrv.defaultLang);

    const iconKeys: string[] = Object.keys(Icons);
    iconKeys.forEach(key => {
      this.matIconRegistry.addSvgIcon(
        key, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/svg/${Icons[key]}.svg`));
    });

    this._translateSrv.onLangChange.subscribe(() => {
      // Translate MatPaginator
      this.matPaginatorIntl.firstPageLabel = this._translateSrv.instant('Paginator.FirstPageLabel');
      this.matPaginatorIntl.itemsPerPageLabel = this._translateSrv.instant('Paginator.ItemsPerPageLabel');
      this.matPaginatorIntl.lastPageLabel = this._translateSrv.instant('Paginator.LastPageLabel');
      this.matPaginatorIntl.nextPageLabel = this._translateSrv.instant('Paginator.NextPageLabel');
      this.matPaginatorIntl.previousPageLabel = this._translateSrv.instant('Paginator.PreviousPageLabel');
      this.matPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
          return `0 ${this._translateSrv.instant('Paginator.Of')} ${length}`;
        }

        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
        return `${startIndex + 1} – ${endIndex} ${this._translateSrv.instant('Paginator.Of')} ${length}`;
      };
    });

    this.titleService.setTitle(`Atalaya v${environment.version}`);
  }

  ngOnInit(): void {
    this.updateApp();
    this.checkUpdate();
  }

  ngOnDestroy(): void {
    this.subscriptions$.forEach(sub => sub.unsubscribe())
  }

  updateApp() {
    if (!this.swUpdateService.isEnabled) {
      return;
    }
    this.subscriptions$.push(this.swUpdateService.available.subscribe(event => {
      this.swUpdateService.activateUpdate().then(() => location.reload())
    }))
  }

  checkUpdate() {
    this.subscriptions$.push(this.appRef.isStable.pipe(
      filter(isStable => isStable === true),
      switchMap(() => interval(8 * 60 * 60 * 1000)),
      switchMap(() => this.swUpdateService.checkForUpdate()),
    ).subscribe())
  }
}
