import { Injectable, Injector } from '@angular/core';
import { AngularFirestore, Query, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { combineLatest, from, Observable, of } from 'rxjs';
import { Constructable } from '@common/interfaces/constructable.interface';

import _ from 'lodash';
import { FirestorePagination, FirestoreType, ModelAdapter } from '@common/interfaces/firestore/firestore.interface';
import { OrderByClause, Pagination, PaginationResponse, WhereClause } from '@common/interfaces/pagination.interface';
import { base64ToFireBlob, dateToFireTimestamp, fireBlobToBase64, fireTimestampToDate } from '../meta-functions';
import { ConstructorModel } from '../models/constructor.model';
import { map } from 'rxjs/operators';

/**
 * Servicio para mapear documentos sobre objetos (ODM)
 *
 * Firestore Interface:
 * [link]{@link https://github.com/angular/angularfire/blob/master/docs/firestore/collections.md#the-documentchangeaction-type}
 *
 * interface DocumentChangeAction {
 *   //'added' | 'modified' | 'removed';
 *  type: DocumentChangeType;
 *  payload: DocumentChange;
 * }
 *
 * interface DocumentChange {
 *  type: DocumentChangeType;
 *  doc: DocumentSnapshot;
 *  oldIndex: number;
 *  newIndex: number;
 * }
 *
 * interface DocumentSnapshot {
 *  exists: boolean;
 *  ref: DocumentReference;
 *  id: string;
 *  metadata: SnapshotMetadata;
 *  data(): DocumentData;
 *  get(fieldPath: string): any;
 * }
 */

@Injectable()
export abstract class AbstractFirestoreProviderService {
  protected _firestoreSrv: AngularFirestore;

  protected _firePagination: FirestorePagination = {
    firstDoc: null,
    lastDoc: null,
    availableNext: false,
    availablePrev: false,
    offset: 0
  };

    /** provider constructor */
  constructor(private _abstractInjector: Injector) {
    this._firestoreSrv = _abstractInjector.get(AngularFirestore);
  }

  /**
   * GET method provider.
   * Set modelClass param to null for object response or "Class" needed to modelate response.
   * @param query: next pagination state
   * @param [filterList]: (Optional) collection of filters
   * @param [orderByList]: (Optional) collection of orders by
   */
  public abstract getAll(query: Pagination, filterList?: Array<WhereClause>, orderByList?: Array<OrderByClause>): Observable<any>;

  /**
   * GET method provider by ID.
   * Set modelClass param to null for object response or "Class" needed to modelate response.
   * @param id: document id to find
   * @param modelClass: (Optional) class to instanciate in return method. Null for object response
   */
  public abstract getById(id: string): Observable<any>;

  /**
   * ADD method provider.
   * Set modelClass param to null for object response or "Class" needed to modelate response.
   * @param data: object to create
   * @param returned: (Optional) if true then launch a getById
   */
  public abstract add(data: object, returned?: boolean): Observable<any>;

  /**
   * UPDATE method provider.
   * Set modelClass param to null for object response or "Class" needed to modelate response.
   * @param id: document id to update
   * @param data: object to update
   * @param returned: (Optional) if true then launch a getById
   */
  public abstract update(id: string, data: object, returned?: boolean): Observable<any>;

  /**
   * DELETE method provider.
   * Set modelClass param to null for object response or "Class" needed to modelate response.
   * @param id: document id to update
   */
  public abstract delete(id: string): Observable<any>;

  // populateObs(doc, field: string): Observable<any> {
  //     console.log(doc, field)
  //     return new Observable(observer => {
  //       doc[field].get().then(response => {
  //           observer.next(response.data());
  //           observer.complete();
  //       });
  //     });
  // }

  /************************ PAGINATION METHODS *************************/
  protected _clearPagination() {
    this._firePagination.firstDoc = null;
    this._firePagination.lastDoc = null;
    this._firePagination.availableNext = false;
    this._firePagination.availablePrev = false;
    this._firePagination.offset = 0;
  }

  protected _updatePagination(docs: Array<QueryDocumentSnapshot<any>>, query: Pagination) {
    this._firePagination.firstDoc = _.first(docs);
    this._firePagination.lastDoc = _.last(docs);
    this._firePagination.availableNext = docs.length > query.limit; // sirve sólo si estratégia limit + 1 en petición
    this._firePagination.availablePrev = query.offset !== 0;
    this._firePagination.offset = query.offset;
  }

  protected _generatePaginationQuery(fireQuery: Query,
                                     query: Pagination,
                                     modelClass?: any,
                                     whereList?: Array<WhereClause>,
                                     orderByList?: Array<OrderByClause>): Query {
    if (whereList) {
      for (const where of whereList) {
        const field = this._getFirestoreField(modelClass.adapter(), where.field);
        fireQuery = fireQuery.where(field, where.opertor, where.value);
      }
    }

    if (orderByList) {
      for (const orderBy of orderByList) {
        const field = this._getFirestoreField(modelClass.adapter(), orderBy.field);
        fireQuery = fireQuery.orderBy(field, orderBy.direction);
      }
    }

    // se solicitan limit + 1, para start debe incluirse el último valor
    if (query.offset) {
      if (query.offset > this._firePagination.offset) {
        fireQuery = fireQuery.startAt(this._firePagination.lastDoc);
      } else {
        fireQuery = fireQuery.endBefore(this._firePagination.firstDoc);
      }
    }

    // limit = 50 => se solicitan 51 para saber si hay más paginación (técnica de los 80...)
    if (query.limit) {
      fireQuery  = fireQuery.limit(query.limit + 1);
    }

    return fireQuery;
  }

  /************************ PAGINTATION RESPONSE METHODS *************************/
  protected _newPaginationResponse(query: Pagination): PaginationResponse {
    return {
      data: [],
      total: 0,
      limit: _.get(query, 'limit'),
      offset: _.get(query, 'offset'),
      next: false,
      prev: false
    } as PaginationResponse;
  }

  protected _generatePaginationResponse(docs: Array<QueryDocumentSnapshot<any>>,
                                        query: Pagination,
                                        modelClass: any): PaginationResponse {
    const response = this._newPaginationResponse(query);

    if (_.isEmpty(docs)) {
      return response;
    }

    this._updatePagination(docs, query);

    response.total = Math.min(_.size(docs), query.limit);
    response.next = this._firePagination.availableNext;
    response.prev = this._firePagination.availablePrev;

    for (let idx = 0; idx < response.total; idx++) {
      const item = docs[idx];
      response.data.push(this._modelateData({ id: item.id, ...item.data() }, modelClass));
    }

    return response;
  }

  /************************ PROTECTED METHODS *************************/

  protected _getFirestoreField(modelAdapter: ModelAdapter, field: string): string {
    return  _.get(modelAdapter, [field, 'name'], field);
  }

  protected _getFieldFromFirestore(modelAdapter: ModelAdapter, field: string): string {
    return _.findKey(modelAdapter, { name: field }) || field;
  }

  protected _populate(doc: any, fields: Array<string>, modelClass: any): Observable<any> {
    const obsList: Array<any> = [of(doc)];
    const modelAdapter: ModelAdapter = modelClass.adapter();

    for (const field of fields) {
      if (_.get(modelAdapter, [field, 'type']) === FirestoreType.reference) {
        obsList.push(from(_.get(doc, modelAdapter[field].name).get().then(a => a)));
      }
    }

    return combineLatest(obsList).pipe(
      map((response) => {
        if (_.isEmpty(response)) {
          return of(null);
        }

        const data = response.shift();

        for (let idx = 0; idx < _.size(fields); idx++) {
          const pItem: any = response[idx];
          const pAdapter = modelAdapter[fields[idx]];

          const pData = {
            id: _.get(pItem, 'id'),
            path: _.get(pItem, 'path') || _.get(pItem, 'ref.path'),
            ...pItem.data()
          };

          data[fields[idx]] = this._modelateData(pData, pAdapter.modelClass);
        }

        return this._modelateData(data, modelClass);
      })
    );
  }

  /**
   * Returns data modelated
   */
  protected _modelateData(response: any, modelClass?: any): any {
    if (_.isNil(response) || _.isNil(modelClass)) {
      return response;
    }

    const data = response;

    if (Array.isArray(data)) {
      const responseList = [];

      data.forEach(element => {
        responseList.push(this._createClass(modelClass, element, modelClass.adapter()));
      });

      return responseList;
    }

    return this._createClass(modelClass, data, modelClass.adapter());
  }

  /**
   * Class factory
   * ctor: class name
   * params: (optional)
   */
  protected _createClass<T>(ctor: Constructable<T>, params?: object, modelAdapter?: ModelAdapter): T {
    const paramsCast = {};

    if (modelAdapter) {
      _.forEach(params, (value, field) => {
        const modelField = this._getFieldFromFirestore(modelAdapter, field);
        const modelType = _.get(modelAdapter, [modelField, 'type']) as FirestoreType;

        if (modelType === FirestoreType.byte) {
          paramsCast[modelField] = fireBlobToBase64(value);

        } else if (modelType === FirestoreType.datetime) {
          paramsCast[modelField] = fireTimestampToDate(value);

        } else if (modelType === FirestoreType.reference && !(value instanceof ConstructorModel)) {
          paramsCast[modelField] = _.pick(value, ['id', 'path']);

        } else {
          paramsCast[modelField] = value;
        }
      });
    }

    return new ctor(paramsCast);
  }

  protected _modelateFirestoreData(response: any, modelClass?: any): any {
    if (_.isNil(response) || _.isNil(modelClass)) {
      return response;
    }

    const data = response;

    if (Array.isArray(data)) {
      const responseList = [];

      data.forEach(element => {
        responseList.push(this._createFireDoc(element, modelClass.adapter()));
      });

      return responseList;
    }

    return this._createFireDoc(data, modelClass.adapter());
  }

  protected _createFireDoc(params?: object, modelAdapter?: ModelAdapter): any {
    const paramsCast = {};

    if (modelAdapter) {
      _.forEach(params, (value, field) => {
        const fireField = this._getFirestoreField(modelAdapter, field);
        const modelType = _.get(modelAdapter, [field, 'type']) as FirestoreType;

        if (modelType === FirestoreType.byte) {
          paramsCast[fireField] = base64ToFireBlob(value);

        } else if (modelType === FirestoreType.datetime) {
          paramsCast[fireField] = dateToFireTimestamp(value);

        } else if (modelType === FirestoreType.reference && !(value instanceof ConstructorModel)) {
          paramsCast[fireField] = _.pick(value, ['id', 'path']);

        } else if (modelType === FirestoreType.collection) {
          // @TODO pendiente de realizar
        } else {
          paramsCast[fireField] = value;
        }
      });
    }

    return paramsCast;
  }

}
