import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';

import { AlertService } from './alert.service';

import { PERSISTENCE_KEYS } from '@common/const/system.const';
import { SessionModel } from '@common/models/session/session.model';
import { UserModel } from '@common/models/user/user.model';

import _ from 'lodash';
import { HistorySession } from '@common/models/session/histoy-session.model';
import { startOfYesterday } from 'date-fns';

@Injectable()
export class SessionService implements OnDestroy {
  private _subject: BehaviorSubject<SessionModel> = new BehaviorSubject<SessionModel>(this.getSession());
  private _authSubs$: Subscription;

  /** constructor */
  constructor(private _alertSrv: AlertService) { }

  ngOnDestroy(): void {
    if (!_.isNil(this._authSubs$) && !this._authSubs$.closed) {
      this._authSubs$.unsubscribe();
    }
  }

  /**
   * Returns an observable instance for session data
   */
  onChange(): Observable<SessionModel> {
    return this._subject.asObservable();
  }

  /**
   * Save data session in localstorage
   */
  startSession(session: SessionModel): boolean {
    if (!this.validateSession(session)) {
      return false;
    }

    localStorage.setItem(PERSISTENCE_KEYS.session, JSON.stringify(session));
    localStorage.setItem(PERSISTENCE_KEYS.hsession, JSON.stringify(this.getHistorySession(session)));
    this._subject.next(session);

    return true;
  }

  /**
   * Return the user data session
   */
  getSession(): SessionModel {
    const session = this.loadSession();

    if (!this.validateSession(session)) {
      return null;
    }

    return session;
  }

  /**
   * Delete user data session
   */
  closeSession(): boolean {
    localStorage.removeItem(PERSISTENCE_KEYS.session);


    return true;
  }

  /**
   * Delete user data session and show alert
   */
  expireSession() {
    localStorage.removeItem(PERSISTENCE_KEYS.session);

    this._subject.next(null);
  }

  /**
   * Return if exist a valid session
   */
  existSession(): boolean {
    return this.validateSession(this.loadSession());
  }

  /**
   * Returns the userModel session
   */
  getUserSession(): UserModel {
    return this.existSession()
      ? this.getSession().user
      : null;
  }

  /**
   * Update user in session storage
   */
  updateUserSession(user: UserModel): boolean {
    if (!this.existSession() || _.isNil(user)) {
      return false;
    }

    const session = this.getSession();
    session.user = user;
    return this.startSession(session);
  }

  getHistoryUserLogged(): HistorySession[] {
    return JSON.parse(localStorage.getItem(PERSISTENCE_KEYS.hsession));
  }

  getHistoryUserToggle(id: string) {
    const historySession = this.getHistoryUserLogged();
    historySession.forEach(hs => {
      hs.id === id ? (hs.toogleUser = true) : (hs.toogleUser = false);
    });
    localStorage.setItem(PERSISTENCE_KEYS.hsession, JSON.stringify(historySession));
  }

  checkUserInRole(role: string): boolean {
    const session = this.getUserSession();
    return session.role.id === role;
  }

  /**************************** PRIVATE METHODS *********************************/

  /**
   * Return if data is a valid SessionModel
   */
  private validateSession(session: SessionModel): boolean {
    return !_.isNil(session) && !_.isNil(session.uid) && session.uid !== '';
  }

  /**
   * Return the Session localStorage
   */
  private loadSession(): SessionModel {
    return new SessionModel(
      JSON.parse(localStorage.getItem(PERSISTENCE_KEYS.session))
    );
  }

  private getHistorySession(session: SessionModel): HistorySession[] {
    const historySession = this.getHistoryUserLogged();
    let newSession: HistorySession[] = [];
    if (historySession) {
      historySession.forEach((hs, i) => {
        const hsDate = new Date(hs.date);
        hs.toogleUser = false;
        if (hsDate > startOfYesterday()) {
          newSession.push(hs);
        }
      });
      const isUser = newSession.find(usr => usr.id === session.user.id);
      if (!isUser) {
        newSession.push({
          id: session.user.id,
          date: new Date(),
          fullName: session.user.name + ' ' + session.user.surname,
          username: session.username,
          toogleUser: false
        });
      }
    } else {
      newSession = [
        {
          id: session.user.id,
          date: new Date(),
          fullName: session.user.name + ' ' + session.user.surname,
          username: session.username,
          toogleUser: false
        }
      ];
    }
    return newSession;
  }
}
