import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { Roles } from '@common/models/user/roles.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  public readonly collectionRoles = 'ROLES';
  constructor(private af: AngularFirestore) {}

  public getRoleById(id: string): Observable<Roles> {
    return this.af.doc<Roles>(`${this.collectionRoles}/${id}`).valueChanges();
  }

  public getRoles(): Observable<Roles[]> {
    return this.af.collection<Roles>(`${this.collectionRoles}`).valueChanges();
  }

  public getRolesWith2FA(): Observable<Roles[]> {
    const query: QueryFn = (ref) => {
      return ref.where('TienePincode', '==', true);
    };
    return this.queryRoles(query);
  }

  public getRolesWithJournalSigning(): Observable<Roles[]> {
    const query: QueryFn = (ref) => {
      return ref.where('Fichaje', '==', true);
    };
    return this.queryRoles(query);
  }

  private queryRoles(query: QueryFn): Observable<Roles[]> {
    return this.af.collection<Roles>(`${this.collectionRoles}`, query).get().pipe(
      map(docSnapshots => docSnapshots.docs.map(doc => doc.data()))
    );
  }
}
