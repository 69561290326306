import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class LoaderService {

  private _subject = new Subject<object>();
  /** constructor */
  constructor(private _router: Router) {
    this._router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          this.stop();
        }
      }, () => {
      this.stop();
    });
  }

  // ********************* PUBLIC METHODS ************************* //

  /**
   * add loader item to poolling
   */
  start(msg?: string) {
    this._subject.next({ status: true, msg });
  }

  /**
   * remove loader item from pooling
   */
  stop() {
    this._subject.next({ status: false });
  }

  /**
   * Returns an observable instance
   */
  getLoader(): Observable<object> {
    return this._subject.asObservable();
  }
}
