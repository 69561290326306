export enum Roles {
  CONSEJERA_ECI = 1,
  PROMOTOR,
  ADMINISTRADORES,
  R_DIRECTORS,
  CONSEJERA_LOREAL,
  EXPERTO,
  CONFINAMIENTO,
  RAMS,
  COORDINADORES,
  JJSS,
  BAJA,
  FORMADORES,
  GESTION,
  PERFUMERIAS,
  CONSEJERA_ECI_GC,
  JJSS_GC,
  BAJA_GC,
  FORMADORES_GC,
  PROMOTOR_GC,
  ADMINISTRADORES_GC,
  R_DIRECTORS_GC,
  CONSEJERA_LOREAL_GC,
  EXPERTO_GC,
  CONFINAMIENTO_GC,
  RAMS_GC,
  COORDINADORES_GC
}
