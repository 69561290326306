<div id="loader" *ngIf="loaderPooling.length > 0">
    <div class="loader-bg">
      <div class="loader-spinner">    
        
        <mat-progress-spinner
          class=""
          [color]="'accent'"
          [mode]="'indeterminate'"
          [diameter]="100"
          [value]="50">
        </mat-progress-spinner>
        <h1 *ngIf="message" [innerHtml]="message"></h1>
      </div>
    </div>
  </div>