import { Injectable, Injector, OnDestroy } from '@angular/core';
import {
  combineLatest,
  EMPTY,
  Observable,
  of,
  throwError,
  zip,
  pipe,
  from
} from 'rxjs';
import { filter, map, switchMap, concatMap, take, tap } from 'rxjs/operators';

import { AbstractFirestoreProviderService } from '@common/core/service/abstract-firestore-provider.service';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentReference,
  Query,
  QueryFn
} from '@angular/fire/firestore';

import {
  OrderByClause,
  Pagination,
  WhereClause
} from '@common/interfaces/pagination.interface';

import { UserModel } from '@common/models/user/user.model';
import { UserSigningModel } from '@common/models/user/user-signing.model';
import { UserBrandStandModel } from '@common/models/user/user-brand-stand.model';

import _ from 'lodash';
import { firestore } from 'firebase/app';
import moment from 'moment';
import { UserBrandStandCenters } from '@common/interfaces/user-brand-stand-centers.interface';
import { Brand } from '@common/interfaces/brand.interface';
import { Stand } from '@common/interfaces/stand.interface';
import { Center } from '@common/interfaces/center.interface';
import { Zone } from '@common/interfaces/zone.interface';
import { Roles } from '@common/enums/roles.enum';
import { SessionService } from '../shared/session.service';
import { CenterService } from '../center/center.service';
import { StandService } from '../stand/stand.service';
import { ZoneService } from '../zone/zone.service';
import { BrandService } from '../brand/brand.service';
import { format } from 'date-fns';
import { User } from '@common/interfaces/user.interface';
import { filterItemsByUnfulfilledCancellationDate } from '@common/core/meta-functions';
import { RolesService } from '../role/roles.service';

@Injectable({
  providedIn: 'root'
})
export class UserProviderService
  extends AbstractFirestoreProviderService
  implements OnDestroy {
  public readonly collectionName = 'USUARIOS';
  public get user(): UserModel {
    return this.sessionService.getUserSession();
  }

  public get userCenterScope(): 'all' | 'zone' | 'self' | 'none' {
    switch (Number(this.user.role.id)) {
      case Roles.ADMINISTRADORES:
      case Roles.GESTION:
      case Roles.R_DIRECTORS:
      case Roles.EXPERTO:
      case Roles.FORMADORES:
        return 'all';
      case Roles.RAMS:
        return 'zone';
      case Roles.CONSEJERA_ECI:
      case Roles.CONSEJERA_LOREAL:
      case Roles.COORDINADORES:
      case Roles.JJSS:
      case Roles.PROMOTOR:
        return 'self';
      case Roles.CONFINAMIENTO:
      case Roles.BAJA:
        return 'none';
      case Roles.ADMINISTRADORES_GC:
      case Roles.R_DIRECTORS_GC:
      case Roles.EXPERTO_GC:
      case Roles.FORMADORES_GC:
        return 'all';
      case Roles.RAMS_GC:
        return 'zone';
      case Roles.CONSEJERA_ECI_GC:
      case Roles.CONSEJERA_LOREAL_GC:
      case Roles.COORDINADORES_GC:
      case Roles.JJSS_GC:
      case Roles.PROMOTOR_GC:
        return 'self';
      case Roles.CONFINAMIENTO_GC:
      case Roles.BAJA_GC:
        return 'none';
    }
  }

  public get userBrandScope(): 'all' | 'self' | 'none' {
    switch (Number(this.user.role.id)) {
      case Roles.ADMINISTRADORES:
      case Roles.GESTION:
      case Roles.R_DIRECTORS:
      case Roles.RAMS:
      case Roles.COORDINADORES:
      case Roles.FORMADORES:
        return 'all'; // Limited by userCenterScope
      case Roles.CONSEJERA_ECI:
      case Roles.CONSEJERA_LOREAL:
      case Roles.EXPERTO:
      case Roles.JJSS:
      case Roles.PROMOTOR:
        return 'self';
      case Roles.CONFINAMIENTO:
      case Roles.BAJA:
        return 'none';
      case Roles.ADMINISTRADORES_GC:
      case Roles.R_DIRECTORS_GC:
      case Roles.RAMS_GC:
      case Roles.COORDINADORES_GC:
      case Roles.FORMADORES_GC:
        return 'all'; // Limited by userCenterScope
      case Roles.CONSEJERA_ECI_GC:
      case Roles.CONSEJERA_LOREAL_GC:
      case Roles.EXPERTO_GC:
      case Roles.JJSS_GC:
      case Roles.PROMOTOR_GC:
        return 'self';
      case Roles.CONFINAMIENTO_GC:
      case Roles.BAJA_GC:
        return 'none';
    }
  }

  constructor(
    _injector: Injector,
    private sessionService: SessionService,
    private zoneService: ZoneService,
    private centerService: CenterService,
    private standService: StandService,
    private brandService: BrandService,
    private af: AngularFirestore,
    private rolesService: RolesService
  ) {
    super(_injector);
  }

  ngOnDestroy() {
    this._clearPagination();
  }

  public getUsers(options: {
    filters?: { textSearch: string };
    limit?: number;
    getUnsubscribedUsers?: boolean;
  }): Observable<User[]> {
    return zip(
      ...this.searchByField(options?.filters?.textSearch).map(field =>
        this.af
          .collection<User>(this.collectionName, ref =>
            this.getSearchQuery(ref, field, options)
          )
          .get()
          .pipe(map(snapshot => snapshot.docs.map(d => d.data())))
      )
    ).pipe(
      map(users => {
        const hash: { [key: number]: boolean } = {};
        return users
          .flat()
          .filter(user => hash[user.ID_USUARIO] ? false : (hash[user.ID_USUARIO] = true))
          .slice(0, options.limit)
          .map(user => ({
            ...user,
            FECHA_ALTA_USUARIO: user.FECHA_ALTA_USUARIO && ((user.FECHA_ALTA_USUARIO as unknown) as firestore.Timestamp)?.toDate(),
            FECHA_BAJA_USUARIO: user.FECHA_BAJA_USUARIO && ((user.FECHA_BAJA_USUARIO as unknown) as firestore.Timestamp)?.toDate()
          } as unknown as User));
      })
    );
  }

  public subscribeUser(userId: string):Promise<void>  {
    return this.af.collection<Partial<User>>(this.collectionName).doc(userId).set({ FECHA_BAJA_USUARIO: null }, { merge: true });
  }
  
  public unSubscribeUser(userId: string):Promise<void> {
    const date =  firestore.Timestamp.fromDate(new Date()) 
    return this.af.collection<Partial<User>>(this.collectionName).doc(userId).set({ FECHA_BAJA_USUARIO: date }, { merge: true });
  }

  private searchByField(textSearch: string): string[] {
    // Check filters
    return textSearch
      // Check if is email
      ? /@/.test(textSearch)
        ? ['EMAIL']
        // Check if includes number
        : /^\d+$/.test(textSearch)
          ? ['ID_USUARIO']
          // Is string
          : ['NOMBRE_USUARIO']
      // No filters
      : [''];
  }

  private getSearchQuery<T>(
    ref: Query<T>,
    field: string,
    options: {
      filters?: { textSearch: string };
      offset?: number;
      limit?: number;
      getUnsubscribedUsers?: boolean;
    }
  ): Query<T> {
    let query = options?.getUnsubscribedUsers
      ? ref
      : ref.where('FECHA_BAJA_USUARIO', '==', null);

    if (field) {
      const isIdUser = field === 'ID_USUARIO';
      const formatField = isIdUser
        ? Number(options.filters.textSearch)
        : field === 'EMAIL'
          ? options.filters.textSearch
          : options.filters.textSearch.toUpperCase();
      if (isIdUser) {
        query = query.where(field, '==', formatField);
      } else {
        query = query
          .orderBy(field)
          .startAt(formatField)
          .endAt(formatField + '\uf8ff');
      }
    }

    if (options.limit) {
      query = query.limit(options.limit);
    }

    return query;
  }


  userName(user: User): string {
    if (!user) {
      return '';
    }

    return `${user.NOMBRE_USUARIO || ''} ${user.APELLIDOS_USUARIO || ''}`;
  }
  getAll(
    query: Pagination,
    whereList?: Array<WhereClause>,
    orderByList?: Array<OrderByClause>
  ): Observable<any> {
    const collectionRef: AngularFirestoreCollection<any> = this._firestoreSrv.collection<any>(
      'USUARIOS',
      ref => {
        return this._generatePaginationQuery(
          ref,
          query,
          UserModel,
          whereList,
          orderByList
        );
      }
    );

    return collectionRef.snapshotChanges().pipe(
      map(collection => {
        const docs = _.map(collection, 'payload.doc');

        return this._generatePaginationResponse(docs, query, UserModel);
      })
    );
  }

  getById(id: string = this.user.id): Observable<any> {
    const userRef: AngularFirestoreDocument<any> = this._firestoreSrv.doc<any>(
      `USUARIOS/${id}`
    );
    const populateFields = ['userType', 'role', 'pisoRole'];

    return userRef.valueChanges().pipe(
      switchMap(response => {
        if (_.isNil(response)) {
          return of(null);
        }

        response['id'] = id;
        return this._populate(response, populateFields, UserModel);
      })
    );
  }

  getByEmail(email: string): Observable<any> {
    const formatteEmail = email.trim().toLowerCase();
    const query = ref =>
      ref.where(UserModel.adapter().email.name, '==', formatteEmail).limit(1);
    const populateFields = ['userType', 'role', 'pisoRole'];

    const itemsCollection: AngularFirestoreCollection<any> = this._firestoreSrv.collection(
      'USUARIOS',
      query
    );

    return itemsCollection.valueChanges({ idField: 'id' }).pipe(
      switchMap(response => {
        if (_.isNil(response) || _.isEmpty(response)) {
          return of(null);
        }

        if (_.size(response) > 1) {
          throwError({ key: 'ODM.MultipleBy' });
        }

        return this._populate(_.first(response), populateFields, UserModel);
      })
    );
  }

  public getUserRefById(id: number | string): DocumentReference<User> {
    return this._firestoreSrv.doc<User>(`USUARIOS/${id}`).ref;
  }

  public getUsersByCenterStandBrand(filters: {
    default?: boolean;
    center?: Center;
    stand?: Stand;
    brand?: Brand;
  }): Observable<User[]> {
    return this._firestoreSrv
      .collectionGroup<UserBrandStandCenters>('USUARIO_MARCA_STAND', ref => {
        let query = ref;

        if (filters.default === true || filters.default === false) {
          query = query.where('DEFECTO', '==', filters.default);
        }

        if (filters.center && !filters.stand) {
          query = query.where('ID_CENTRO', '==', filters.center.ID_CENTRO);
        }

        if (filters.stand) {
          query = query.where('ID_STAND', '==', filters.stand.ID_STAND);
        }

        if (filters.brand) {
          query = query.where('ID_MARCA', '==', filters.brand.ID_MARCA);
        }
        return query;
      })
      .valueChanges()
      .pipe(
        map(users => filterItemsByUnfulfilledCancellationDate<UserBrandStandCenters>(users, 'FECHA_BAJA_UMC')),
        tap(userBrandStands => this.ordenarUsuarios(userBrandStands)),
        switchMap(userBrandStands =>    // filtrando por stand aquí no le gusta
          zip(
            ...userBrandStands.map(ubs =>
              this._firestoreSrv.doc<User>(ubs.Ref_ID_USUARIO).valueChanges()
            )
          )
        ),
        map(rolUser => {
          let data = rolUser
            .filter(rolId => rolId !== undefined)
            .filter(
              rolId => rolId.USU_ROL_Id === 1
                || rolId.USU_ROL_Id === 2
                || rolId.USU_ROL_Id === 5
                || rolId.USU_ROL_Id === 6
                || rolId.USU_ROL_Id === 7
                || rolId.USU_ROL_Id === 9
                || rolId.USU_ROL_Id === 10
                || rolId.USU_ROL_Id === 12
                || rolId.USU_ROL_Id === 15
                || rolId.USU_ROL_Id === 16
                || rolId.USU_ROL_Id === 19
                || rolId.USU_ROL_Id === 22
                || rolId.USU_ROL_Id === 23
                || rolId.USU_ROL_Id === 24
                || rolId.USU_ROL_Id === 26
            );

          let result = eliminarObjetosDuplicados(data, 'EMAIL');

          function eliminarObjetosDuplicados( arr, prop ) {
            let nuevoArray = [];
            let lookup  = {};

            for (let i in arr) {
              lookup[arr[i][prop]] = arr[i];
            }

            for (let i in lookup) {
              nuevoArray.push(lookup[i]);
            }

            return nuevoArray;
          }

          return result;
        }),
        switchMap(users => 
          this.rolesService.getRoles().pipe(
            map(roles => {
              if(roles){
                const idDiv = JSON.parse(localStorage.getItem('userDiv')).roleData.ID_DIVISION;
                const filteredRoles = roles.filter(role => role['ID_DIVISION'] === idDiv);
                return users.filter(user => filteredRoles.some(role => user.USU_ROL_Id === role.ROL_Id));
              } else {
                return users;
              }
            })
          )
        )
      );
  }

  private getDefaultUserBrandStand(): Observable<UserBrandStandCenters> {
    return this.userBrandStandRef({
      id: this.user.id,
      queryFn: ref => ref.where('DEFECTO', '==', true)
    })
      .valueChanges()
      .pipe(map(csbs => csbs?.[0]));
  }

  public getDefaultZone(): Observable<Zone> {
    let datoUsuarioDiv  = JSON.parse(localStorage.getItem('userDiv'));
    let idDiv = datoUsuarioDiv.roleData.ID_DIVISION;
    if (idDiv === '01'){
      return this.getDefaultCenter().pipe(
        switchMap(center => this.zoneService.getZoneByRef(center.Ref_ZONA_AQ_GC)
        )
      );
    }else{
      return this.getDefaultCenter().pipe(
        switchMap(center => this.zoneService.getZoneByRef(center.Ref_ZONA_AQ)
        )
      );
    }
  }

  public getDefaultCenter(): Observable<Center> {
    return this.getDefaultUserBrandStand().pipe(
      switchMap(csb => this.centerService.getCenterByRef(csb.Ref_ID_CENTRO))
    );
  }

  public getDefaultStand(): Observable<Stand> {
    return this.getDefaultUserBrandStand().pipe(
      switchMap(csb => this.standService.getStandByRef(csb.Ref_ID_STAND))
    );
  }

  public getDefaultUseBrandStand(): Observable<UserBrandStandCenters> {
    return this.getDefaultUserBrandStand();
  }

  public  isStandDischarged = (stand:UserBrandStandCenters): boolean => {
    const currentDate = new Date();
    const dischargeDate = new Date(stand.FECHA_BAJA_UMC?.toDate());
    return currentDate >= dischargeDate;
}

  public getDefaultBrand(): Observable<Brand> {
    return this.getDefaultUserBrandStand().pipe(
      switchMap(csb => this.brandService.getBrandByRef(csb.Ref_ID_MARCA))
    );
  }

  public getStandsByCenter(center: Center, centerFirestorePath: 'CENTROS' | 'CENTROS_ZZTT'): Observable<Stand[]> {
    return this._firestoreSrv
      .collection<Center>(
        centerFirestorePath
      ).doc(center.ID_CENTRO.toString())
      .collection<Stand>('CENTRO_STANDS')
      .get()
      .pipe(
        map(stands => stands.docs.map(stand => stand.data())),
        map(stands => filterItemsByUnfulfilledCancellationDate<Stand>(stands, 'FECHA_BAJA_STAND')),
        map(
          stands =>
            this.standService.getUniqueStands(
              stands
            ) as Stand[]
        ),
        map(stands => stands.filter(stand => !stand?.FECHA_BAJA_STAND)),
        tap(stands => this.ordenarStands(stands))
      );
  }

  public getDefaultZones(): Observable<Zone[]> {
    switch (this.userCenterScope) {
      case 'all':
        return this.zoneService.getAllZones();
      case 'zone':
      case 'self':
        return this.getDefaultZone().pipe(map(zone => [zone]));
      case 'none':
        return throwError("Users with this role can't query zones.");
      default:
        return throwError('Unhandled case.');
    }
  }

  public getDefaultCenters(): Observable<Center[]> {
    switch (this.userCenterScope) {
      case 'all':
        return this.centerService.getAllCenters();
      case 'zone':
        return this.getDefaultZone().pipe(
          switchMap(zone => this.centerService.getCentersByZone(zone))
        );
      case 'self':
        return this.getDefaultCenter().pipe(map(center => [center]));
      case 'none':
        return throwError("Users with this role can't query centers.");
      default:
        return this.centerService.getAllCenters();
      //return throwError('Unhandled case.');
    }
  }

  public getDefaultStandsByCenter(center: Center): Observable<Stand[]> {
    return this._firestoreSrv
      .collection<UserBrandStandCenters>(
        `USUARIOS/${this.user.id}/USUARIO_MARCA_STAND`,
        ref => ref
        .where('ID_CENTRO', '==', center.ID_CENTRO)
      )
      .valueChanges()
      .pipe(
        map(users => filterItemsByUnfulfilledCancellationDate<UserBrandStandCenters>(users, 'FECHA_BAJA_UMC')),
        map(
          userBrandStands =>
            this.standService.getUniqueStands(
              userBrandStands
            ) as UserBrandStandCenters[]
        ),
        switchMap(userBrandStands =>
          combineLatest(
            userBrandStands.map(ubs =>
              this._firestoreSrv.doc<Stand>(ubs.Ref_ID_STAND).valueChanges()
            )
          )
        ),
        map(stands => stands.filter(stand => !stand?.FECHA_BAJA_STAND)),
        tap(stands => this.ordenarStands(stands))
      );
  }

  public getDefaultBrands(): Observable<Brand[]> {
    switch (this.userBrandScope) {
      case 'all':
        switch (this.userCenterScope) {
          case 'all':
            return this.brandService.getAllBrands();
          case 'zone':
            return this.getDefaultZone().pipe(
              switchMap(zone => this.brandService.getBrandsByZone(zone))
            );
          case 'self':
            return this.getDefaultCenter().pipe(
              switchMap(center => this.brandService.getBrandsByCenter(center))
            );
          case 'none':
            return throwError("Users with this role can't query brands.");
          default:
            return throwError('Unhandled case.');
        }
      case 'self':
        return this.getDefaultBrand().pipe(map(brand => [brand]));
      case 'none':
        return throwError("Users with this role can't query brands.");
      default:
        return throwError('Unhandled case.');
    }
  }

  public getBrandsInDefaultStand(): Observable<Brand[]> {
    return this.getDefaultStand().pipe(
      switchMap(stand => this.brandService.getBrandsByStand(stand))
    );
  }

  public unsubscribeUserBrandStandDoc(userId: number, standId: number, brandId: number) {
    this._firestoreSrv
      .doc(
        `USUARIOS/${userId}/USUARIO_MARCA_STAND/${userId}-${standId}-${brandId}`
      )
      .update({ FECHA_BAJA_UMC: firestore.Timestamp.fromDate(new Date()) });
  }

  getUserBrandStandList(userId?:string, queryFn?: QueryFn): Observable<[Brand[], Stand[], Center[], UserBrandStandCenters[]]> {
    const id = userId || this.user?.id;
    const userBrandStandRef = this.userBrandStandRef({ id , queryFn});

    return userBrandStandRef
      .valueChanges({ idField: 'USUARIO_MARCA_STAND_ID' })
      .pipe(
        map((data: UserBrandStandCenters[]): any => {
          const brandPathArray = [];
          const standPathArray = [];
          const centerPathArray = [];
          const userBrandStands = [];
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element?.Ref_ID_MARCA?.path) {
              brandPathArray.push(element.Ref_ID_MARCA.path);
            }
            if (element?.Ref_ID_STAND?.path) {
              standPathArray.push(element.Ref_ID_STAND.path);
            }
            if (element?.Ref_ID_CENTRO?.path) {
              centerPathArray.push(element.Ref_ID_CENTRO.path);
            }
            userBrandStands.push(element);
          }
          const uniqueBrands = _.uniq(brandPathArray);
          const uniqueStands = _.uniq(standPathArray);
          const uniqueCenters = _.uniq(centerPathArray);
          const brandsQueries = _.map(uniqueBrands, path => {
            return this._firestoreSrv.doc<Brand>(path).valueChanges();
          });
          const standsQueries = _.map(uniqueStands, path => {
            return this._firestoreSrv.doc<Stand>(path).valueChanges();
          });
          const centerQueries = _.map(uniqueCenters, path => {
            return this._firestoreSrv.doc<Center>(path).valueChanges();
          });
          return [
            combineLatest([...brandsQueries]),
            combineLatest([...standsQueries]),
            combineLatest([...centerQueries]),
            of(userBrandStands)
          ];
        }),
        switchMap(
          (
            arrayObs: [
              Observable<Brand[]>,
              Observable<Stand[]>,
              Observable<Center[]>,
              Observable<UserBrandStandCenters[]>
            ]
          ) => {
            return combineLatest(arrayObs);
          }
        )
      );
  }

  getUserBrandStands(): Observable<UserBrandStandCenters[]> {
    return this.userBrandStandRef({
      id: this.user.id,
    })
      .valueChanges()
      .pipe(map(csbs => csbs));
  }

  getUserBrandStandCentersList(queryFn?: QueryFn): Observable<[Brand[], Stand[], Center[]]> {
    if (!this.user?.id) {
      throw new Error('User ID is not available');
    }
  
    const userBrandStandRef = this.userBrandStandRef({ id: this.user.id, queryFn });
  
    return userBrandStandRef.valueChanges({ idField: 'USUARIO_MARCA_STAND_ID' }).pipe(
      map((data: UserBrandStandCenters[]): [Observable<Brand[]>, Observable<Stand[]>, Observable<Center[]>] => {
        const brandPaths = data.map(d => d.Ref_ID_MARCA?.path).filter(Boolean);
        const standPaths = data.map(d => d.Ref_ID_STAND?.path).filter(Boolean);
        const centerPaths = data.map(d => d.Ref_ID_CENTRO?.path).filter(Boolean);
  
        const uniqueBrandsPaths = _.uniq(brandPaths);
        const uniqueStandsPaths = _.uniq(standPaths);
        const uniqueCentersPaths = _.uniq(centerPaths);
  
        const brandsObservables = combineLatest(uniqueBrandsPaths.map((path: string) => this._firestoreSrv.doc<Brand>(path).valueChanges({idField: "DOC_ID"}))) as Observable<Brand[]>;
        const standsObservables = combineLatest(uniqueStandsPaths.map(path => this._firestoreSrv.doc<Stand>(path).valueChanges({idField: "DOC_ID"}))) as Observable<Stand[]>;
        const centersObservables = combineLatest(uniqueCentersPaths.map(path => this._firestoreSrv.doc<Center>(path).valueChanges({idField: "DOC_ID"}))) as Observable<Center[]>;
  
        return [brandsObservables, standsObservables, centersObservables];
      }),
      switchMap(observables => combineLatest(observables))
    );
  }
  
  public updateUserBrandStand(brandId: number, standId: number) {
    const userId = this.user?.id;
    return this.userBrandStandRef({
      id: userId,
      queryFn: ref => {
        return ref.where('DEFECTO', '==', true);
      }
    })
      .snapshotChanges()
      .pipe(
        take(1),
        concatMap(doc => doc.length >= 1 
          ? from(doc[0].payload.doc.ref.update({ DEFECTO: false }))
          : EMPTY
        ),
        concatMap(() =>
          from(
            this._firestoreSrv
              .doc(
                `USUARIOS/${userId}/USUARIO_MARCA_STAND/${userId}-${standId}-${brandId}`,
              )
              .update({ DEFECTO: true }),
          ),
        ),
        map(() => {
          return brandId;
        })
      );
  }

  public getUserBrandStandCenter(idCenter: number, idStand: number): Observable<any> {
    if(idCenter != null){
      return this._firestoreSrv
        .doc(`USUARIOS/${this.user?.id}`)
        .collection<UserBrandStandCenters>('USUARIO_MARCA_STAND',
          ref => ref
          .where('ID_CENTRO', '==', idCenter)
        )
        .get()
        .pipe(
          map(snapshot => snapshot.docs.map(doc => doc.data())),
          map(users => filterItemsByUnfulfilledCancellationDate<UserBrandStandCenters>(users, 'FECHA_BAJA_UMC')),
          );
    }
    if(idStand != null){
      return this._firestoreSrv
        .doc(`USUARIOS/${this.user?.id}`)
        .collection<UserBrandStandCenters>('USUARIO_MARCA_STAND',
          ref => 
          ref.where('ID_STAND', '==', idStand))
        .get().pipe(
          map(snapshot => snapshot.docs.map(doc => doc.data())),
          map(users => filterItemsByUnfulfilledCancellationDate<UserBrandStandCenters>(users, 'FECHA_BAJA_UMC')),
          );
    }
    if(idCenter == null && idStand == null){
      return this._firestoreSrv
        .doc(`USUARIOS/${this.user?.id}`)
        .collection<UserBrandStandCenters>('USUARIO_MARCA_STAND',
          ref => ref)
        .get().pipe(
          map(snapshot => snapshot.docs.map(doc => doc.data())),
          map(users => filterItemsByUnfulfilledCancellationDate<UserBrandStandCenters>(users, 'FECHA_BAJA_UMC')),
          );
    }
  }

  private userBrandStandRef({
    id,
    queryFn
  }: {
    id: string;
    queryFn?: QueryFn;
  }) {
    const userRef: AngularFirestoreDocument<any> = this._firestoreSrv.doc<any>(
      `USUARIOS/${id}`
    );
    const userBrandStandRef = queryFn
      ? userRef.collection<UserBrandStandCenters>(
        UserModel.adapter().brandStand.name,
        queryFn
      )
      : userRef.collection<UserBrandStandCenters>(
        UserModel.adapter().brandStand.name
      );
    return userBrandStandRef;
  }

  getUserBrandStandFirst(id: string = this.user?.id): Observable<any> {
    const userBrandStandRef = this.userBrandStandRef({ id });

    return userBrandStandRef.snapshotChanges().pipe(
      map(collection => {
        const docs = _.map(collection, 'payload.doc');

        if (_.isEmpty(docs)) {
          return null;
        }

        const populateFields = ['brand', 'stand'];
        return this._populate(
          _.first(docs),
          populateFields,
          UserBrandStandModel
        );
      })
    );
  }

  getUserBrandStandByDefault(
    id: string = this.user?.id
  ): Observable<[Brand, Stand, Center]> {
    return this.userBrandStandRef({
      id: id,
      queryFn: ref => {
        return ref.where('DEFECTO', '==', true);
      }
    })
      .valueChanges()
      .pipe(
        switchMap(data => {
          const center: Observable<Center> = from<Observable<Center>>(
            _.get(data, [0, 'Ref_ID_CENTRO'])
              .get()
              .then(document => document.data())
          );
          const stand: Observable<Stand> = from<Observable<Stand>>(
            _.get(data, [0, 'Ref_ID_STAND'])
              .get()
              .then(document => document.data())
          );
          const brand: Observable<Brand> = from<Observable<Brand>>(
            _.get(data, [0, 'Ref_ID_MARCA'])
              .get()
              .then(document => document.data())
          );

          return combineLatest([brand, stand, center]);
        })
      );
  }

  getUserJournalSigning(accion?: boolean): Observable<any> {
    const id = this.user.id;
    const todayDateObjectStart = new Date(new Date().setHours(0, 0, 0));
    const todayDateObjectEnd = new Date(new Date().setHours(23, 59, 59));
    const userRef = this._firestoreSrv.collection<any>(
      `USUARIOS/${id}/FICHAJE_APP`,
      ref => {
        return ref
          .where(
            UserSigningModel.adapter().dateHour.name,
            '>=',
            firestore.Timestamp.fromDate(todayDateObjectStart)
          )
          .where(
            UserSigningModel.adapter().dateHour.name,
            '<=',
            firestore.Timestamp.fromDate(todayDateObjectEnd)
          );
      }
    );
    return userRef.valueChanges({ idField: 'id' }).pipe(
      map(data => {
        return data.sort((a, b) => {
          return moment(a.FECHA_HORA.toDate()).isAfter(b.FECHA_HORA.toDate())
            ? -1
            : 1;
        });
      }),
      map(
        ordListSigning =>
          accion ===
          !!ordListSigning[0]?.[UserSigningModel.adapter().entry.name]
      )
    );
  }

  addJournalSigning(ES_ENTRADA?: boolean): Observable<any> {
    const id = this.user.id;
    const FECHA_HORA = new Date();
    return combineLatest([
      this.getDefaultCenter(),
      this.getDefaultStand()
    ]).pipe(
      switchMap(([center, stand]: [center: Center, stand: Stand]) => {
        const refIdStand = this._firestoreSrv.doc(
          `/CENTROS/${center.ID_CENTRO}/CENTRO_STANDS/${stand.ID_STAND}`
        ).ref;
        const refIdUsuario = this._firestoreSrv.doc(`USUARIOS/${id}`).ref;
        const datosDeFichaje = {
          ES_ENTRADA,
          FECHA_HORA,
          ID_STAND: stand.ID_STAND,
          ID_USUARIO: parseFloat(id),
          Ref_ID_STAND: refIdStand,
          Ref_ID_USUARIO: refIdUsuario,
          ULTIMO_TIPO_FICHAJE: 2
        };
        const idDoc = `${id}-${format(FECHA_HORA, 'yyyyMMdd HH:mm:ss')}`;
        return from(
          this._firestoreSrv
            .collection<any>(`USUARIOS/${id}/FICHAJE_APP`)
            .doc(idDoc)
            .set(datosDeFichaje)
        );
      })
    );
  }

  add(data: object, returned?: boolean): Observable<any> {
    const fireData = this._modelateFirestoreData(data, UserModel);
    const addObs = from(
      this._firestoreSrv.collection<any>(`USUARIOS`).add(fireData)
    );

    return returned
      ? addObs.pipe(switchMap(docRef => this.getById(docRef.id)))
      : addObs.pipe(map(docRef => new UserModel({ id: docRef.id })));
  }

  update(
    id: string,
    data: User & { center: Center; stand: Stand; brands: Brand[] },
    returned?: boolean
  ): Observable<any> {
    const { center, stand, brands, ...user } = data;
    const updateObs = from(
      this._firestoreSrv.doc<any>(`USUARIOS/${id}`).update(user)
    );

    return returned
      ? updateObs.pipe(switchMap(() => this.getById(id)))
      : updateObs;
  }

  updateIdLoreal(
    idLoreal: User['ID_LOREAL'],
    idUser: User['ID_USUARIO']
  ): Promise<void> {
    return this._firestoreSrv.doc<User>(`USUARIOS/${idUser}`).update({
      ID_LOREAL: idLoreal.toString()
    });
  }

  delete(id: string): Observable<any> {
    return from(this._firestoreSrv.doc<any>(`USUARIOS/${id}`).delete());
  }

  /**
   * Metodo para cambio de rol de usuario
   * @param idUser id del usuario
   * @param usuRolId rol a cambiar
   */
  setUserRole(idUser: number, usuRolId: number): Observable<any> {
    return from(
      this._firestoreSrv
        .doc<any>(`USUARIOS/${idUser}`)
        .update({ USU_ROL_Id: usuRolId,
          Ref_USU_ROL_Id: this.af.doc(`ROLES/${usuRolId}`).ref
        }));
  }

  public ordenarStands(items:any[]) {
    return items.sort(function(a,b){
      if (a.DESCRIPCION_STAND > b.DESCRIPCION_STAND ) {
        return 1;
      }
      if (a.DESCRIPCION_STAND  < b.DESCRIPCION_STAND ) {
        return -1;
      }
      return 0;
    });
  }

  public ordenarUsuarios(items:any[]) {
    return items.sort(function(a,b){
      if (a.NombreBusqueda > b.NombreBusqueda ) {
        return 1;
      }
      if (a.NombreBusqueda  < b.NombreBusqueda ) {
        return -1;
      }
      return 0;
    });
  }
}
