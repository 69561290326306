import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
// import swal from 'sweetalert2';

import _ from 'lodash';

@Injectable()
export class AlertService {

  /** constructor */
  constructor(private _translateSrv: TranslateService,
              private _router: Router) { }

  // ********************* PUBLIC METHODS ************************* //
  success(title?: string, msg?: string, btn?: string, timeout?: number) {
    const sTitle = title || this._translateSrv.instant('FormSuccess.Alert.SuccesDefault.TitleLabel');
    const sText = msg || this._translateSrv.instant('FormSuccess.Alert.SuccesDefault.TextLabel');
    const sBtn = btn || this._translateSrv.instant('FormSuccess.Alert.SuccesDefault.CloseBtn');

    this.show(sTitle, sText, sBtn, 'success', timeout);
  }

  show(title?: string, msg?: string, btn?: string, type?: any, timeout?: number) {
    const sTitle = title || this._translateSrv.instant('FormError.Alert.Default.TitleLabel');
    const sText = msg || this._translateSrv.instant('FormError.Alert.Default.TextLabel');
    const sBtn = btn || this._translateSrv.instant('FormError.Alert.Default.CloseBtn');
    const sType = type || 'error';

    // swal({
    //   title: sTitle,
    //   text: sText,
    //   type: sType,
    //   confirmButtonText: sBtn,
    //   confirmButtonClass: 'mat-raised-button mat-accent mx-1',
    //   buttonsStyling: false,
    //   onOpen: () => {
    //     if (!_.isNil(timeout) && _.isNumber(timeout)) {
    //       setTimeout(() => {
    //         swal.close();
    //       }, timeout);
    //     }
    //   }
    // });
  }

  expired() {
    alert(this._translateSrv.instant('Session.Alert.Expired.TextLabel'));
    // swal({
    //   title: this._translateSrv.instant('Session.Alert.Expired.TitleLabel'),
    //   html: this._translateSrv.instant('Session.Alert.Expired.TextLabel'),
    //   type: 'error',
    //   allowOutsideClick: false,
    //   allowEscapeKey: false,
    //   allowEnterKey: false,
    //   showConfirmButton: false,
    //   showCancelButton: false,
    //   showCloseButton: false,
    //   onOpen: () => {
    //     swal.showLoading();

    //     setTimeout(() => {
    //       this._router.navigateByUrl('/login');
    //       swal.close();
    //     }, 5000);
    //   }
    // });
  }

  unauthorized() {
    // swal({
    //   title: this._translateSrv.instant('Session.Alert.Unauthorized.TitleLabel'),
    //   html: this._translateSrv.instant('Session.Alert.Unauthorized.TextLabel'),
    //   type: 'error',
    //   confirmButtonText: this._translateSrv.instant('Session.Alert.Unauthorized.CloseBtn'),
    //   confirmButtonClass: 'mat-raised-button mat-accent mx-1',
    //   buttonsStyling: false,
    // });
  }

  outOfService() {
    // swal({
    //   title: this._translateSrv.instant('Session.Alert.OutOfService.TitleLabel'),
    //   html: this._translateSrv.instant('Session.Alert.OutOfService.TextLabel'),
    //   type: 'error',
    //   confirmButtonText: this._translateSrv.instant('Session.Alert.OutOfService.CloseBtn'),
    //   confirmButtonClass: 'mat-raised-button mat-accent mx-1',
    //   buttonsStyling: false,
    // });
  }

  serviceNoFound() {
    // swal({
    //   title: this._translateSrv.instant('Session.Alert.ServiceNoFound.TitleLabel'),
    //   html: this._translateSrv.instant('Session.Alert.ServiceNoFound.TextLabel'),
    //   type: 'error',
    //   confirmButtonText: this._translateSrv.instant('Session.Alert.ServiceNoFound.CloseBtn'),
    //   confirmButtonClass: 'mat-raised-button mat-accent mx-1',
    //   buttonsStyling: false,
    // });
  }

  timeout() {
    // swal({
    //   title: this._translateSrv.instant('Session.Alert.TimeoutRequest.TitleLabel'),
    //   html: this._translateSrv.instant('Session.Alert.TimeoutRequest.TextLabel'),
    //   type: 'error',
    //   confirmButtonText: this._translateSrv.instant('Session.Alert.TimeoutRequest.CloseBtn'),
    //   confirmButtonClass: 'mat-raised-button mat-accent mx-1',
    //   buttonsStyling: false,
    // });
  }

  /************************ PRIVATE METHODS *************************/
}
