import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import _ from 'lodash';
import moment from 'moment';

/** Pipe for datetimes */
@Pipe({
  name: 'atetime'
})
export class DatetimePipe implements PipeTransform {
  /** constructor */
  constructor(private _translateSrv: TranslateService) { }

  /**
   * Returns datetime string formatted
   * @example {{myDatetime | atetime:true:false}}
   * @param value: date to format
   * @param [seconds] seconds: seconds flag
   * @param [withoutHourTag] withoutHourTag: hour suffix flag
   */
  transform(value:	string | number | Date, seconds?: boolean, withoutHourTag?: boolean): string {
    const key = seconds ? 'DatetimeAndSeconds' : 'Datetime';
    const hourTag = withoutHourTag ? '' : 'h';
    const format = this._translateSrv.instant(`DateFormat.Moment.${key}`);
    const empty = this._translateSrv.instant(`DateFormat.Empty.${key}`);

    return (!_.isNil(value) ? moment(value).format(format) : empty) + hourTag;
  }

}
