import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserModel } from '@common/models/user/user.model';
import { UserProviderService } from '@common/services/providers/user-provider.service';
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  public readonly user: UserModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { type: 'start' | 'end' },
    private userService: UserProviderService
  ) {
    this.user = this.userService.user;
  }
}
