import { ConstructorModel } from '@common/core/models/constructor.model';
import { FirestoreType, ModelAdapter } from '@common/interfaces/firestore/firestore.interface';
import { BrandModel } from './brand.model';
import { ZoneTypeModel } from './zone-type.model';

export class StandBrandModel extends ConstructorModel {
  id: string;
  path: string;
  registrationDate: string;
  unregistrationDate: string;
  groupId: string;
  paraPiso: string;
  qvRecordingPercentage: number;
  enableFidelManual: boolean;
  brand: BrandModel;
    // stand: StandModel;
  qvZone: ZoneTypeModel;

  static adapter(): ModelAdapter {
    return {
        registrationDate: { name: 'FECHA_ALTA_MS', type: FirestoreType.datetime },
        unregistrationDate: { name: 'FECHA_BAJA_MS', type: FirestoreType.datetime },
        groupId: { name: 'ID_GRUPO', type: FirestoreType.string },
        paraPiso: { name: 'PARA_PISO', type: FirestoreType.string },
        qvRecordingPercentage: { name: 'PORCENTAJE_GRABACION_QV', type: FirestoreType.number },
        enableFidelManual: { name: 'PUEDE_FIDEL_MANUAL', type: FirestoreType.boolean },
        brand: { name: 'Ref_ID_MARCA', type: FirestoreType.reference, modelClass: BrandModel },
            // stand: {name: 'Ref_ID_STAND', type: FirestoreType.reference, modelClass: StandModel},
        qvZone: { name: 'Ref_ZONA_QV', type: FirestoreType.reference, modelClass: ZoneTypeModel }
      };
  }

    /** model constructor */
  constructor(data?: object) {
    super();

    this.id = this.setId('id', data);
    this.path = this.setString('path', data);
    this.registrationDate = this.setDate('registrationDate', data);
    this.unregistrationDate = this.setDate('unregistrationDate', data);
    this.groupId = this.setString('groupId', data);
    this.paraPiso = this.setString('paraPiso', data);
    this.qvRecordingPercentage = this.setNumber('qvRecordingPercentage', data);
    this.enableFidelManual = this.setBoolean('enableFidelManual', data);
    this.brand = this.setEntity('brand', BrandModel, data);
        // this.stand = this.setEntity('stand', StandModel, data);
    this.qvZone = this.setEntity('qvZone', ZoneTypeModel, data);
  }
}
