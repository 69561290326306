import { Component, OnInit, Injector } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '@common/services/shared/session.service';

import { UserModel } from '@common/models/user/user.model';
import { SessionModel } from '@common/models/session/session.model';

import _ from 'lodash';
import { Router } from '@angular/router';
import { LoggerService } from '@common/services/shared/logger.service';
// const swal = require('sweetalert2');

@Component({
  selector: 'app-meta-simple',
  template: ''
})
export class MetaSimpleComponent implements OnInit {
  public subcriptionOnDestroy$ = {};
  public subcriptionApi$ = {};
  public userLogged: UserModel;
  public _sessionSrv: SessionService;
  public _translateSrv: TranslateService;
  public _router: Router;
  public _loggerSrv: LoggerService;

  /** constructor */
  constructor(private injectorMetaSimple: Injector) {
    this._translateSrv = injectorMetaSimple.get(TranslateService);
    this._sessionSrv = injectorMetaSimple.get(SessionService);
    this._router = injectorMetaSimple.get(Router);
    this._loggerSrv = injectorMetaSimple.get(LoggerService);

    this.userLogged = this._sessionSrv.getUserSession();

    this.subcriptionOnDestroy$['session'] = this._sessionSrv.onChange().subscribe(
      (session: SessionModel) => {
        const userSession = this._sessionSrv.getUserSession();

        if (!_.isNil(userSession) && userSession.getDifferences(this.userLogged).length > 0) {
          this.userLogged = userSession;
        }
      }
    );
  }

  ngOnInit() { }

  /************************ PROTECTED METHODS **************************/

  /**
   * Returns if a subscription is alive or not
   */
  public isAliveSubscriptionDestroy(idx: string): boolean {
    const subscription = _.get(this.subcriptionOnDestroy$, 'commandStackChanged') as Subscription;
    return !_.isNil(subscription) && !subscription.closed;
  }

  /**
   * Unsubscribe subscriptions in destroy list
   */
  public unsubscribeOnDestroy() {
    this.unsubscribeList(this.subcriptionOnDestroy$);
  }

  /**
   * Unsubscribe the subscription in api list or all subscriptions if subscription is undefined
   */
  public unsubscribeApi(subscription?: string) {
    this.unsubscribeList(
      !_.isNil(subscription)
        ? _.pick(this.subcriptionApi$, subscription)
        : this.subcriptionApi$
    );
  }

  /**
   * Show alert error
   */
  public showAlertModal(path: string, type: string = 'error', customText?: string, timeout?: number) {
    alert(this._translateSrv.instant(`${path}.TextLabel`));
    // const text = this.___translateSrv.instant(customText || path + '.TextLabel');

    // swal({
    //   title: this.___translateSrv.instant(path + '.TitleLabel'),
    //   html: text,
    //   type: type,
    //   confirmButtonText: this.___translateSrv.instant(path + '.CloseBtn'),
    //   confirmButtonClass: 'mat-raised-button mat-accent mx-1',
    //   buttonsStyling: false,
    //   onOpen: () => {
    //     if (timeout) {
    //       setTimeout(() => {
    //         swal.close();
    //       }, timeout);
    //     }
    //   }
    // });
  }

  /**
   * Show alert modal without dismiss option and redirect by timeout
   */
  public showAlertModalCallback(path: string, callback: string, paramsCallback?: any, type: string = 'error', customText?: string) {
    /* const text = this.___translateSrv.instant(customText || path + '.TextLabel');

    swal({
      title: this.___translateSrv.instant(path + '.TitleLabel'),
      html: text,
      type,
      confirmButtonText: this.___translateSrv.instant(path + '.CloseBtn'),
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: false,
      confirmButtonClass: 'mat-raised-button mat-accent mx-1',
      buttonsStyling: false,
    }).then((result) => {
      _.isNil(paramsCallback) ? this[callback]() : this[callback](paramsCallback);
    }); */
  }

  /**
   * Show modal error no dismissable with timeout redirect
   */
  public showAlertModalTimeout(path: string, url: string, type: string = 'error', customText?: string) {
    // const text = this.___translateSrv.instant(customText || path + '.TextLabel');
    // const goTo = _.isNil(url)
    //   ? _.initial(this.___router.url.split('/')).join('/')
    //   : url;

    // swal({
    //   title: this.___translateSrv.instant(path + '.TitleLabel'),
    //   html: text,
    //   type: type,
    //   allowOutsideClick: false,
    //   allowEscapeKey: false,
    //   allowEnterKey: false,
    //   showConfirmButton: false,
    //   showCancelButton: false,
    //   onOpen: () => {
    //     swal.showLoading();

    //     setTimeout(() => {
    //       this.___router.navigateByUrl(goTo);
    //       swal.close();
    //     }, 5000);
    //   }
    // });
  }

  /**
   * Show alert modal without dismiss option and redirect by timeout
   */
  public showAlertModalRedirect(path: string, url: string, type: string = 'error', customText?: string) {
    // const text = this.___translateSrv.instant(customText || path + '.TextLabel');

    // swal({
    //   title: this.___translateSrv.instant(path + '.TitleLabel'),
    //   html: text,
    //   type: type,
    //   confirmButtonText: this.___translateSrv.instant(path + '.CloseBtn'),
    //   allowOutsideClick: false,
    //   allowEscapeKey: false,
    //   allowEnterKey: false,
    //   showCancelButton: false,
    //   confirmButtonClass: 'mat-raised-button mat-accent mx-1',
    //   buttonsStyling: false,
    // }).then((result) => {
    //   this.___router.navigateByUrl(url);
    // });
  }

  /**
   * Show confirm modal
   */
  public showConfirmModal(
    path: string,
    callbackOk: string,
    paramsOk?: any,
    callbackCancel?: string,
    paramsCancel?: any,
    customText?: string
  ) {
    // const text = this.___translateSrv.instant(customText || path + '.TextLabel');

    // swal({
    //   title: this.___translateSrv.instant(path + '.TitleLabel'),
    //   html: text,
    //   type: 'warning',
    //   showCancelButton: true,
    //   confirmButtonText: this.___translateSrv.instant(path + '.YesBtn'),
    //   cancelButtonText: this.___translateSrv.instant(path + '.NoBtn'),
    //   confirmButtonClass: 'mat-raised-button mat-accent mx-1',
    //   cancelButtonClass: 'mat-raised-button mat-warn mx-1',
    //   buttonsStyling: false,
    //   reverseButtons: true,
    //   allowOutsideClick: false,
    //   allowEscapeKey: false,
    //   allowEnterKey: false,
    //   showCloseButton: false
    // }).then((result) => {
    //   if (result.value) {
    //     _.isNil(paramsOk) ? this[callbackOk]() : this[callbackOk](paramsOk);
    //   } else if (!_.isNil(callbackCancel)) {
    //     _.isNil(paramsCancel) ? this[callbackCancel]() : this[callbackCancel](paramsCancel);
    //   }
    // }, (dismiss) => {
    //   _.isNil(paramsCancel) ? this[callbackCancel]() : this[callbackCancel](paramsCancel);
    // });
  }

  /*************************** PRIVATE METHODS ******************************/

  /**
   * Unsbuscribe a subscription list
   */
  private unsubscribeList(subscriptons$: object) {
    if (!_.isNil(subscriptons$) && !_.isEmpty(subscriptons$)) {
      _.forIn(subscriptons$, (subscriptor: Subscription) => {
        if (!_.isNil(subscriptor) && !subscriptor.closed) {
          subscriptor.unsubscribe();
        }
      });
    }
  }
}
