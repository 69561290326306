<div id="loader-nav" *ngIf="visible">
  <div class="loader-nav-bg">
    <div class="loader-nav-spinner">    
      <mat-progress-spinner
        class=""
        [color]="'accent'"
        [mode]="'indeterminate'"
        [diameter]="100"
        [value]="50">
      </mat-progress-spinner>
    </div>
  </div>
</div>