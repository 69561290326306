import { ConstructorModel } from '@common/core/models/constructor.model';
import { FirestoreType, ModelAdapter } from '@common/interfaces/firestore/firestore.interface';
import { BrandModel } from '../stand/brand.model';
import { StandModel } from '../stand/stand.model';

export class UserBrandStandModel extends ConstructorModel {
  id: string;
  path: string;
  default: boolean;
  registrationDate: string;
  unregistrationDate: string;
  brand: BrandModel;
  stand: StandModel;

  static adapter(): ModelAdapter {
    return {
        default: { name: 'DEFECTO', type: FirestoreType.string },
        registrationDate: { name: 'FECHA_ALTA_UMC', type: FirestoreType.datetime },
        unregistrationDate: { name: 'FECHA_BAJA_UMC', type: FirestoreType.datetime },
        defaultChangeDate: { name: 'FECHA_CAMBIO_DEFECTO', type: FirestoreType.datetime },
        brand: { name: 'Ref_ID_MARCA', type: FirestoreType.reference, modelClass: BrandModel },
        stand: { name: 'Ref_ID_STAND', type: FirestoreType.reference, modelClass: StandModel }
      };
  }

    /** model constructor */
  constructor(data?: object) {
    super();

    this.id = this.setId('id', data);
    this.path = this.setString('path', data);
    this.default = this.setBoolean('default', data);
    this.registrationDate = this.setDate('registrationDate', data);
    this.unregistrationDate = this.setDate('unregistrationDate', data);
    this.brand = this.setEntity('brand', BrandModel, data);
    this.stand = this.setEntity('stand', StandModel, data);
  }
}
