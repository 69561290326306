import { ConstructorModel } from '@common/core/models/constructor.model';
import { FirestoreType, ModelAdapter } from '@common/interfaces/firestore/firestore.interface';

export class PisoMenuOptionModel extends ConstructorModel {
  id: string;
  path: string;
  menuCID: string;
  submenuCID: string;
  active: boolean;
  descriptionTxt: string;
  menuSnTxt: string;
  moduleTxt: string;
  permissionTxt: string;

  static adapter(): ModelAdapter {
    return {
        menuCID: { name: 'CID_MENU', type: FirestoreType.string },
        submenuCID: { name: 'CID_SUBMENU', type: FirestoreType.string },
        active: { name: 'NID_ACTIVO', type: FirestoreType.boolean },
        descriptionTxt: { name: 'TXT_DESCRIPCION', type: FirestoreType.string },
        menuSnTxt: { name: 'TXT_MENU_SN', type: FirestoreType.string },
        moduleTxt: { name: 'TXT_MODULO', type: FirestoreType.string },
        permissionTxt: { name: 'TXT_PERMISO', type: FirestoreType.string }
      };
  }

    /** model constructor */
  constructor(data?: object) {
    super();

    this.id = this.setId('id', data);
    this.path = this.setString('path', data);
    this.menuCID = this.setString('pisoRole', data);
    this.submenuCID = this.setString('submenuCID', data);
    this.active = this.setBoolean('active', data);
    this.descriptionTxt = this.setString('descriptionTxt', data);
    this.menuSnTxt = this.setString('menuSnTxt', data);
    this.moduleTxt = this.setString('moduleTxt', data);
    this.permissionTxt = this.setString('permissionTxt', data);
  }
}
