import { ConstructorModel } from '@common/core/models/constructor.model';
import { FirestoreType, ModelAdapter } from '@common/interfaces/firestore/firestore.interface';

export class BrandModel extends ConstructorModel {
  id: string;
  path: string;
  moneyLoyalty: number;
  spacePromoTicket: string;
  registrationDate: string;
  unregistrationDate: string;
  registrationLoyaltyDate: string;
  logo: string;
  monthsExpiryPoints: number;
  monthsForLoyalty: number;
  name: string;
  nameLoyaltyProgram: string;
  nameBrandStyle: string;

  static adapter(): ModelAdapter {
    return {
        moneyLoyalty: { name: 'DINERO_FIDELIDAD', type: FirestoreType.number },
        spacePromoTicket: { name: 'ESPACIO_PROMO_TICKET', type: FirestoreType.string },
        registrationDate: { name: 'FECHA_ALTA_MARCA', type: FirestoreType.datetime },
        unregistrationDate: { name: 'FECHA_BAJA_MARCA', type: FirestoreType.datetime },
        registrationLoyaltyDate: { name: 'FECHA_INICIO_FIDELIDAD', type: FirestoreType.datetime },
        logo: { name: 'LOGO', type: FirestoreType.byte },
        monthsExpiryPoints: { name: 'MESES_CADUCIDAD_PUNTOS', type: FirestoreType.number },
        monthsForLoyalty: { name: 'MESES_PARA_FIDELIDAD', type: FirestoreType.number },
        name: { name: 'NOMBRE_MARCA', type: FirestoreType.string },
        nameLoyaltyProgram: { name: 'NOMBRE_PROGRAMA_FIDELIDAD', type: FirestoreType.string },
        nameBrandStyle: { name: 'NOMBRE_STILO_MARCA', type: FirestoreType.string }
      };
  }

    /** model constructor */
  constructor(data?: object) {
    super();

    this.id = this.setId('id', data);
    this.path = this.setString('path', data);
    this.name = this.setString('name', data);
    this.moneyLoyalty = this.setNumber('moneyLoyalty', data);
    this.spacePromoTicket = this.setString('spacePromoTicket', data);
    this.registrationDate = this.setDate('registrationDate', data);
    this.unregistrationDate = this.setDate('unregistrationDate', data);
    this.registrationLoyaltyDate = this.setDate('registrationLoyaltyDate', data);
    this.logo = this.setString('logo', data);
    this.monthsExpiryPoints = this.setNumber('monthsExpiryPoints', data);
    this.monthsForLoyalty = this.setNumber('monthsForLoyalty', data);
    this.nameLoyaltyProgram = this.setString('nameLoyaltyProgram', data);
    this.nameBrandStyle = this.setString('nameBrandStyle', data);
  }
}
