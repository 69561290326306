export enum Permission {
  UsuarioMisDatos = 'PISO_UsuarioMisDatos',
  UsuarioRanking = 'PISO_UsuarioRanking',
  GestionPersonalPondera = 'PISO_GestionPersonalPondera',
  GestionUsuariosRoles = 'PISO_GestionUsuariosRoles',
  GestionObjetivos = 'PISO_GestionObjetivos',
  GestionPilares = 'PISO_GestionPilares',
  GestionObjetivosPilares = 'PISO_GestionObjetivosPilares',
  GestionEmisionRRHH = 'PISO_GestionEmisionRRHH',
  GestionListadoStandsPDM = 'PISO_GestionListadoStandsPDM',
  Consumidoras = 'Consumidoras',
  Gestion = "Gestion",
  Admin = "Admin"
}

export enum PermAction {
  Borrar = 'Borrar',
  Datos = 'Datos',
  Ejecutar = 'Ejecutar',
  Importar = 'Importar',
  Exportar = 'Exportar',
  Insertar = 'Insertar',
  Modificar = 'Modificar',
  Tope = 'Tope'
}

export enum PermActionOption {
  brand = 'brand',
  area = 'area',
  all = 'all',
  lessEqual = '<='
}
