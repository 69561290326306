/*
Añadir aqui todos los iconos nuevos dentro de icons/svg/
Ejemplo:
    * icons/svg/name-icon.svg
    * Añadimos -> name = 'name-icon'
    * Uso ->  <mat-icon svgIcon='name'></mat-icon>
*/
export enum Icons {
    appointment = 'appointment-icon',
    arrowDown = 'arrow-down-icon',
    asterisco = 'asterisco',
    basket = 'cesta-icon',
    calendar = 'calendar',
    call = 'call-icon',
    callTypeArticle = 'call-type-article',
    callTypeBirthday = 'call-type-birthday',
    callTypeConsumer = 'consumers-icon',
    callTypeWaitlist = 'call-type-waitlist',
    cashbackArticle = 'cashback-article',
    charts = 'charts',
    check = 'check',
    closeMenu = 'close-menu-icon',
    consumer = 'consumer',
    consumerCalled31 = 'consumer-called-31',
    consumerCalled7 = 'consumer-called-7',
    consumers = 'consumers-icon',
    consumerStand = 'consumer-stand',
    counselor = 'counselor-icon',
    info = 'info-icon',
    crown = 'crown',
    delete = 'delete',
    x = 'borrar-icon',
    edit = 'edit',
    mail = 'mail-icon',
    expense = 'gasto-icon',
    fidelity = 'fidel-icon',
    frequency = 'frecuencia-icon',
    globalExpense = 'gasto-global-icon',
    help = 'help-icon',
    home = 'home-icon',
    letter = 'letter-icon',
    list = 'list',
    logout = 'logout-icon',
    modifyData = 'modify-data',
    notifications = 'notifications',
    openMenu = 'open-menu-icon',
    piso = 'piso-icon',
    points = 'points-icon',
    print = 'print-icon',
    queen = 'queen-icon',
    returnSale = 'devolver-icon',
    sales = 'sales-icon',
    scan = 'scan-icon',
    searchScreen = 'lupa',
    sellHistory = 'sell-history',
    sms = 'sms-icon',
    stand = 'stand-icon',
    statistics = 'statistics',
    fullArrowDown = 'flecha',
    thinArrowDown = 'thin-arrow-down-icon',
    ticket = 'ticket-icon',
    unsubscribeCard = 'unsubscribe-card',
    user = 'user-icon',
    vip = 'vip',
    vipManual = 'vip-manual',
    weld = 'fusionar-icon',
    import = 'import',
    elipseGreen = 'elipse-green',
    elipseRed = 'elipse-red',
    iconAdd = 'iconAdd',
    star = 'star',
    star0 = 'star0',
    setting = 'settings2'
}
