import { ConstructorModel } from '@common/core/models/constructor.model';
import { FirestoreType, ModelAdapter } from '@common/interfaces/firestore/firestore.interface';

export class UserTypeModel extends ConstructorModel {
  id: string;
  path: string;
  description: string;
  defaultWeighting: any;

  static adapter(): ModelAdapter {
    return {
        description: { name: 'DESCRIPCION_USUARIO', type: FirestoreType.string },
        defaultWeighting: { name: 'PONDERACION_DEFECTO', type: FirestoreType.string }
      };
  }

    /** model constructor */
  constructor(data?: object) {
    super();

    this.id = this.setId('id', data);
    this.path = this.setString('path', data);
    this.description = this.setString('description', data);
    this.defaultWeighting = this.setAny('defaultWeighting', data);
  }
}
