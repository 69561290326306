import { Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { firestore } from 'firebase/app';
import { Center } from '@common/interfaces/center.interface';
import { Zone } from '@common/interfaces/zone.interface';
import {
  AngularFirestore,
  DocumentReference,
  Query,
} from '@angular/fire/firestore';
import { StandBrand } from '@common/interfaces/stand-brand.interface';

@Injectable({
  providedIn: 'root'
})
export class CenterService {
  public static readonly collectionName = 'CENTROS';
  public static readonly collectionNameZZTT = 'CENTROS_ZZTT';
  public centersFiltered: any[];

  constructor(private af: AngularFirestore) {
  }

  public getAllCenters(): Observable<Center[]> {
    let datoUsuarioDiv = JSON.parse(localStorage.getItem('userDiv'));
    let idDiv = datoUsuarioDiv.roleData.ID_DIVISION;
    // let idDiv = '01';
    if (idDiv === '01') {
      this.getCenterFilteredBycenter();
      return this.af
        .collection<Center>(CenterService.collectionName)
        .valueChanges()
        .pipe(
          tap(centers => this.ordenarCentros(centers)),
          map(centers =>
            centers.filter(
              center =>
                center.FECHA_ALTA_CENTRO > center.FECHA_BAJA_CENTRO &&
                center.GC === true
            )
          )
        );
    } else {
      return this.af
        .collection<Center>(CenterService.collectionName)
        .valueChanges()
        .pipe(
          tap(centers => this.ordenarCentros(centers)),
          map(centers =>
            centers.filter(
              center => center.FECHA_ALTA_CENTRO > center.FECHA_BAJA_CENTRO
            )
          )
        );
    }
  }

  public getCenterFilteredBycenter() {
    const center = JSON.parse(localStorage.getItem('centerRoleLS'));
    return this.af
      .collection(`USUARIOS/${center?.id}/USUARIO_MARCA_STAND`)
      .valueChanges()
      .pipe(
        map(center =>
          center.filter(
            (obj: any, index) =>
              center.findIndex(
                (item: any) => item.ID_CENTRO === obj.ID_CENTRO
              ) === index
          )
        )
      )
      .subscribe(value => {
        this.centersFiltered = value;
      });
  }

  public getCentersByZone(zone: Zone, zztt?: boolean): Observable<Center[]> {
    // console.log('Pasa por getCentersByZone');
    // console.log('zone - getCentersByZone', zone);

    let datoUsuarioDiv = JSON.parse(localStorage.getItem('userDiv'));
    let idDiv = datoUsuarioDiv?.roleData?.ID_DIVISION;
    // let idDiv = '01';
    if (idDiv === '01') {
      this.getCenterFilteredBycenter();
      return this.af
        .collection<Center>(CenterService.collectionName, ref =>
          ref.where('ZONA_AQ_GC', '==', zone.ZONA_AQ)
        )
        .valueChanges()
        .pipe(
          tap(centers => this.ordenarCentros(centers)),
          map(centers =>
            centers.filter(
              center =>
                center.FECHA_ALTA_CENTRO > center.FECHA_BAJA_CENTRO &&
                center.GC === true
            )
          )
        );
    } else {
      return this.af
        .collection<Center>(zztt ? CenterService.collectionNameZZTT : CenterService.collectionName, ref =>
          ref.where('ZONA_AQ', '==', zone.ZONA_AQ)
        )
        .valueChanges()
        .pipe(
          tap(centers => this.ordenarCentros(centers)),
          map(centers =>
            centers.filter(
              center =>
                center.FECHA_ALTA_CENTRO > center.FECHA_BAJA_CENTRO 
                // && center.GC !== true comentado de momento por incidencia NUATl-1040 
                // para comprobar si tiene efectos en la aplicación
            )
          )
        );
    }
  }

  public getCenterById(id: number): Observable<Center> {
    return this.af
      .doc<Center>(`${CenterService.collectionName}/${id}`)
      .valueChanges();
  }

  public getCenterByRef(ref: firestore.DocumentReference): Observable<Center> {
    return this.af.doc<Center>(ref).valueChanges();
  }

  public getCenterRefById(id: number): DocumentReference<Center> {
    return this.af.doc<Center>(`${CenterService.collectionName}/${id}`).ref;
  }

  /** Updates centers in cache. */
  public updateCache(): Observable<void> {
    return this.af
      .collection<Center>(CenterService.collectionName)
      .get()
      .pipe(map(() => undefined));
  }

  public ordenarCentros(items: any[]) {
    return items.sort(function (a, b) {
      if (a.NOMBRE_CENTRO > b.NOMBRE_CENTRO) {
        return 1;
      }
      if (a.NOMBRE_CENTRO < b.NOMBRE_CENTRO) {
        return -1;
      }
      return 0;
    });
  }


  public getCenters(options: {
    filters?: { textSearch: string };
    limit?: number;
  }): Observable<Center[]> {
    return zip(
      ...this.searchByField(options?.filters?.textSearch).map(field =>
        this.af
          .collection<Center>('CENTROS', ref =>
            this.getSearchQuery(ref, field, options)
          )
          .get()
          .pipe(map(snapshot => snapshot.docs.map(d => d.data())))
      )
    ).pipe(
      map(centers => {
        const hash: { [key: number]: boolean } = {};
        return centers
          .flat()
          .filter(center =>
            hash[center.ID_CENTRO] ? false : (hash[center.ID_CENTRO] = true)
          )
          .slice(0, options.limit)
          .map(
            center =>
              (({
                ...center,
                FECHA_ALTA_USUARIO: ((center.FECHA_ALTA_CENTRO as unknown) as firestore.Timestamp)?.toDate(),
                FECHA_BAJA_USUARIO: ((center.FECHA_BAJA_CENTRO as unknown) as firestore.Timestamp)?.toDate()
              } as unknown) as Center)
          );
      })
    );
  }

  public async getCenterByName(name: string):Promise<Center[]>{
    const snapshot = await this.af.collection<Center>(CenterService.collectionName, (query: Query) => {
      query = query
        .where('NOMBRE_CENTRO', '==', name)

      return query;
    }).get().toPromise();

    const data = snapshot.docs.map(doc => doc.data());

    return data;
  }
  
  public getAllBrandsFromStand(centerId: string, standId: string): Observable<number[]> {
    return this.af.collection('CENTROS')
      .doc(centerId)
      .collection('CENTRO_STANDS')
      .doc(standId)
      .collection<StandBrand>('STAND_MARCAS')
      .valueChanges()
      .pipe(
        take(1),
        map((standBrands: StandBrand[]) => standBrands.filter(sb => sb.FECHA_BAJA_MS === null)),
        map((standBrands: StandBrand[]) => standBrands.map(sb => sb.ID_MARCA)),
      )
  }

  private searchByField(textSearch: string): string[] {
    // Check filters
    return textSearch
      // Check if includes number
      ? /^\d+$/.test(textSearch)
        ? ['ID_CENTRO']
        // Is string
        : ['NOMBRE_CENTRO']
    // No filters
    : [''];
  }

  private getSearchQuery<T>(
    ref: Query<T>,
    field: string,
    options: {
      filters?: { textSearch: string };
      offset?: number;
      limit?: number;
    }
  ): Query<T> {
    let query = ref.where('FECHA_BAJA_CENTRO', '==', null);

    if (field) {
      const isIdCenter = field === 'ID_USUARIO';
      const formatField = isIdCenter
        ? Number(options.filters.textSearch)
        : options.filters.textSearch.toUpperCase();
      if (isIdCenter) {
        query = query.where(field, '==', formatField);
      } else {
        query = query
          .orderBy(field)
          .startAt(formatField)
          .endAt(formatField + '\uf8ff');
      }
    }

    if (options.limit) {
      query = query.limit(options.limit);
    }

    return query;
  }
}
