import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@environments/environment';

import _ from 'lodash';
/** @ignore */
const ENVIRONMENT = environment;

/** Pipe for numbers */
@Pipe({
  name: 'number'
})
export class NumberPipe implements PipeTransform {
  /** constructor */
  constructor(private _translateSrv: TranslateService) { }

  /**
   * Returns number formatted
   * @example {{text | number:2.2}}
   */
  transform(value: number | string, digitsInfo: string = '1.2-2', defaultValue: string = '-'): string {
    const locale = ENVIRONMENT.language.default;

    return !_.isNil(value) && (_.isNumber(value) || (_.isString(value) && value !== ''))
      ? formatNumber(Number(value),  locale, digitsInfo)
      : defaultValue;
  }

}
