import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, BehaviorSubject } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

/** @todo Move to new file in models */
export interface BrandTheme {
  ColorPrimary: string;
  ColorSecondary: string;
  ColorTerciary: string | null;
  PathLogo: string;
}

@Injectable()
export class StyleService {
  private readonly _subject: BehaviorSubject<BrandTheme> = new BehaviorSubject(null);
  private _loading: boolean;

  public readonly brandTheme$: Observable<BrandTheme> = this._subject.asObservable();

  public get loading(): boolean {
    return this._loading;
  }

  constructor(
    private af: AngularFirestore,    
    private afStorage: AngularFireStorage
    ) { }

  public newBrand(idBrand: string): void {
    this.getBrandTheme(idBrand).subscribe(
      brandTheme => this._subject.next(brandTheme)
    );
  }

  public getBrandTheme(idBrand: string): Observable<BrandTheme> {
    this._loading = true;
    return this.af.doc<BrandTheme>(`MARCAS/${idBrand}`).get().pipe(
      map(doc => doc.data()),
      finalize(() => this._loading = false)
    );
  }

  public setBrandTheme(brandTheme: BrandTheme): void {
    const updateProperty = (property: string, value: string) => value
      ? globalThis.document.documentElement.style.setProperty(property, value)
      : globalThis.document.documentElement.style.removeProperty(property);



      updateProperty('--primary-color', brandTheme.ColorPrimary);
      updateProperty('--secondary-color', brandTheme.ColorSecondary);
      updateProperty('--terciary-color', brandTheme.ColorTerciary || this.adjustLikeOpacityOverWhiteColor(brandTheme.ColorSecondary, 0.1));

    if (!brandTheme.PathLogo) {
      return;
    }  
    const [fullUrl, path] = brandTheme?.PathLogo?.split('appspot.com/');
    let brand: null | string = null;

    if (path.startsWith('marcas/')) {
      this.afStorage.ref(path).getDownloadURL().subscribe((url) => {
        brand = url;
        updateProperty('--logo-url', `url("${brand}")`);
      })
    } else {
      updateProperty('--logo-url', `url("${brandTheme.PathLogo}")`);
    }
  }

  public adjustLikeOpacityOverWhiteColor(color: string, opacity: number): string {
    const adjustDecimalOverWhite = (n: number) => Math.round(n * opacity + 255 * (1 - opacity));
    const getHexColorFromDecimal = (n: number) => Math.min(255, Math.max(0, n)).toString(16).padStart(2, '0');
    return (
      '#' +
      color
        .replace(/^#/, '')
        .replace(/../g, c => getHexColorFromDecimal(adjustDecimalOverWhite(parseInt(c, 16))))
    );
  }
}
