// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  logs: true,
  language: {
    default: 'es',
    list: ['es']
  },
  firebase: {
    apiKey: 'AIzaSyAdmiyC2sNqMp1SaWEHfxGNn2_eyFbXkJw',
    authDomain: 'loreal-es-atalaya-dev.firebaseapp.com',
    databaseURL: 'https://loreal-es-atalaya-dev.firebaseio.com',
    projectId: 'loreal-es-atalaya-dev',
    storageBucket: 'loreal-es-atalaya-dev.appspot.com',
    messagingSenderId: '625438964001',
    appId: '1:625438964001:web:525711f66038078094e660'
  },
  middleUrl: 'https://ae-middle-atalaya-dot-loreal-es-atalaya-dev.ew.r.appspot.com',
  middle: 'https://ae-middle-atalaya-dot-loreal-es-atalaya-dev.ew.r.appspot.com/call',
  middleBq: 'https://ae-bq-middle-dot-loreal-es-atalaya-dev.ew.r.appspot.com',
  middleKey: '91d9d736d277e374ed60a4c32013c7d7',
  version: require('../../package.json').version
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
