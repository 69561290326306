import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
  selector: 'app-loader-navigation',
  templateUrl: './loader-navigation.component.html',
  styleUrls: ['./loader-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class LoaderNavigationComponent implements OnDestroy {

  public visible = true;
  /** constructor */
  constructor(private _router: Router) {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.visible = true;
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
          this.visible = false;
        }
    }, () => {
      this.visible = false;
    });
  }

  ngOnDestroy(): void {
    this.visible = false;
  }

}
