import { Component, OnInit, Injector } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { VALIDATION_ERRORS } from '@common/const/form.const';
import { MetaSimpleComponent } from './meta-simple.component';
import { ConstructorModel } from '../models/constructor.model';
import { Subscription } from 'rxjs';

import _ from 'lodash';
import { LoaderService } from '@common/services/shared/loader.service';
import { QuickStateMatcher } from '@common/libs/stateMatcher';

export interface ControlDifferenceType {
  cname: string; // control name to comparate
  value: any; // value to comparate
  data?: any; // data to insert in query
}

@Component({
  selector: 'app-meta-form',
  template: ''
})
export class MetaFormComponent extends MetaSimpleComponent implements OnInit {
  public item: any;
  public isNewItem = false;
  public editionMode = false;
  public forma: FormGroup;
  public matcher = new QuickStateMatcher();

  public firstTry: boolean;

  protected _loaderSrv: LoaderService;
  protected itemId = null;
  protected itemBackup: any;

  /** constructor */
  constructor(private injectorMeta: Injector) {
    super(injectorMeta);
    this._loaderSrv = injectorMeta.get(LoaderService);
  }

  ngOnInit() { }

  /************************ DOM METHODS **************************/

  /**
   * Return if a input has error and must be showed
   */
  hasError(cName: string, fGroup?: FormGroup): boolean {
    const fg = _.isNil(fGroup) ? this.forma : fGroup;

    if (_.isNil(_.get(fg.controls, cName))) {
      return false;
    }

    return _.get(fg.controls, cName).invalid;
  }

  /**
   * Return if a input has error and must be showed
   */
  hasErrorExtended(fGroup: FormGroup, cName: string): boolean {
    if (_.isNil(_.get(fGroup, ['controls', cName]))) {
      return false;
    }

    return _.get(fGroup, ['controls', cName, 'invalid']);
  }

  /**
   * Return the error text to validator associated rule
   */
  showError(cName: string, fGroup?: FormGroup): string {
    const fg = _.isNil(fGroup) ? this.forma : fGroup;

    if (!this.hasError(cName, fGroup)) {
      return '';
    }

    const input = _.get(fg.controls, cName) as AbstractControl;
    const error = VALIDATION_ERRORS[_.get(_.keys(input.errors), '[0]')] || _.get(VALIDATION_ERRORS, 'default');
    const toReplace = _.has(error, 'replace')
      ? _.mapValues(error.replace, (replaceKey) =>  _.get(input, replaceKey, ''))
      : undefined;

    return this._translateSrv.instant(error.msg, toReplace);
  }

  /**
   * Return the error text to validator associated rule
   */
  showErrorExtended(fGroup: FormGroup, cName: string): string {
    if (!this.hasErrorExtended(fGroup, cName)) {
      return '';
    }

    const input = _.get(fGroup.controls, cName) as AbstractControl;
    const error = VALIDATION_ERRORS[_.get(_.keys(input.errors), '[0]')] || _.get(VALIDATION_ERRORS, 'default');
    const toReplace = _.has(error, 'replace')
      ? _.mapValues(error.replace, (replaceKey) =>  _.get(input, replaceKey, ''))
      : undefined;

    return this._translateSrv.instant(error.msg, toReplace);
  }

  /**
   * Return if input value exist
   */
  hasValue(cName: string): boolean {
    if (_.isNil(_.get(this.forma.controls, cName))) {
      return false;
    }

    return !_.isNil(_.get(this.forma.controls, cName).value) && _.get(this.forma.controls, cName).value !== '';
  }

  /**
   * Reset the input value
   */
  clearInput(cName: string): void {
    if (!_.isNil(_.get(this.forma.controls, cName))) {
      _.get(this.forma.controls, cName).setValue('');
    }
  }

  /**
   * Returns the input enable status
   */
  isEnabled(cName: string): boolean {
    if (_.isNil(_.get(this.forma.controls, cName))) {
      return false;
    }

    return _.get(this.forma.controls, cName).enabled;
  }

  /************************ PROTECTED METHODS **************************/

  /**
   * Enable edition mode
   */
  protected startEdition(): void {
    if (!_.isNil(this.item) && this.item instanceof ConstructorModel) {
      this.itemBackup = this.item.clone();
    }

    this.firstTry = false;
    this.editionMode = true;
  }

  /**
   * Disable edition mode and restore backup optionally
   */
  protected stopEdition(restore?: boolean): void {
    if (restore && this.item instanceof ConstructorModel) {
      this.item = this.itemBackup.clone();
    }

    this.editionMode = false;
  }

  /**
   * Set flags for new item
   */
  protected setNewMode(): void {
    this.isNewItem = true;
    this.itemId = null;
    this.startEdition();
  }

  /**
   * Enable or disable all form inputs
   */
  protected updateFormDisabledStandard(enable: boolean = true): void {
    if (enable) {
      _.forIn(this.forma.controls, (inputControl: FormControl, name) => {
        inputControl.enable();
      });

    } else {
      _.forIn(this.forma.controls, (inputControl: FormControl, name) => {
        inputControl.disable();
      });
    }
  }

  /**
   * Fill the form with model property that are called equal
   */
  protected updateFormDataStandard(defaultValue: string = '-'): void {
    if (this.editionMode) {
      defaultValue = '';
    }

    this.updateControlDataStandard(this.forma, null, defaultValue);
  }

  /**
   * Recursive method to set values in formControls
   */
  private updateControlDataStandard(control: AbstractControl | any, path?: string, defaultValue?: string) {
    if (control instanceof FormControl) {
      const value = _.isNil(_.get(this.item, path)) || _.get(this.item, path) === '' ? defaultValue : _.get(this.item, path);
      control.setValue(value);

    } else {
      _.forEach(_.get(control, 'controls'), (gControl: AbstractControl | any, key: string | number) => {
        const gKey = _.isNumber(key) ? { path: '[:k]', sep: '' } : { path: ':k', sep: '.' };
        const cPath = _.filter([path, gKey.path.replace(':k', key.toString())], (p) => !_.isNil(p) && p !== '');

        this.updateControlDataStandard(gControl, cPath.join(gKey.sep), defaultValue);
      });
    }
  }

  /**
   * Initializa form with item values
   */
  protected initFormStandard(defaultValue?: string) {
    this.updateFormDataStandard(defaultValue);
    this.updateFormDisabledStandard(false);
  }

  /**
   * Forces to mark as touched the indicated inputs
   */
  protected forceMarkAsTouched(cNameList: Array<string> = []) {
    _.forIn(this.forma.controls, (inputControl: FormControl, name) => {
      if (_.includes(cNameList, name)) {
        inputControl.markAsTouched();
      }
    });
  }

  /**
   * Returns an array that contains the keys of values updated
   */
  protected getDifferencesFromItem(extraList?: Array<ControlDifferenceType>): object {
    const formData = this.forma.getRawValue();
    const diffList = this.itemBackup.getDifferences(formData);
    let query = _.pick(formData, diffList);

    if (_.isNil(extraList)) {
      return query;
    }

    _.forEach(extraList, (extra: ControlDifferenceType) => {
      if (this.forma.controls[extra.cname].value !== extra.value) {
        query = Object.assign(query, extra.data ? extra.data : { cname: this.forma.controls[extra.cname].value });
      }
    });

    return query;
  }

  protected isAliveSubscription(subscription$: Subscription) {
    return !_.isNil(subscription$) && !subscription$.closed;
  }
}
