import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SessionService } from '@common/services/shared/session.service';
import { AuthenticationService } from '@common/services/providers/authentication.service';
import {PermissionService} from '@common/services/permissions/permission.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private sessionService: SessionService,
    private permissionService: PermissionService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    return this.validateAuth();
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    return this.validateAuth();
  }

  private validateAuth(): boolean | Observable<boolean> {
    return this.authService.getCurrentUser().pipe(
      map(user => {
        if (!user) {
          this.sessionService.expireSession();
          this.router.navigate(['/']);
          return false;
        } else {
          const usuario = this.sessionService.getUserSession();
          if (usuario.ZZ_TT == 1) {
            return false;
          } else {
            this.router.events.pipe(
              filter((e): e is NavigationEnd => e instanceof NavigationEnd)
              ).subscribe((e: NavigationEnd) => {
                if (e.url === '/') {
                  this.permissionService.removePermissions();
                  setTimeout(() => localStorage.removeItem('permissions'), 0);
                } else {
                  this.permissionService.loadPermissions();
                }
              });
            return true;
          }
        }
      })
    );
  }
}
